import React, {useState} from "react";
import './AssistantViewSection.scss';
import {CanaiaTitleSimple} from "../../atoms/CanaiaTitleSimple/CanaiaTitleSimple.tsx";
import {CanaiaButtonWithIcon} from "../../atoms/CanaiaButtonWithIcon/CanaiaButtonWithIcon.tsx";
import {CanaiaSettingsPhoneChannelSection} from "../CanaiaSettingsPhoneChannelSection/CanaiaSettingsPhoneChannelSection.tsx";
import {CanaiaSettingsTelegramChannelSection} from "../CanaiaSettingsTelegramChannelSection/CanaiaSettingsTelegramChannelSection.tsx";
import {CanaiaSettingsWhatsAppChannelSection} from "../CanaiaSettingsWhatsAppChannelSection/CanaiaSettingsWhatsAppChannelSection.tsx";
import {CanaiaSettingsWebChannelSection} from "../CanaiaSettingsWebChannelSection/CanaiaSettingsWebChannelSection.tsx";
//import iconos de botones
import IconPhone from '../../../assets/images/phone.svg';
import IconTelegram from '../../../assets/images/telegram.svg';
import IconWhatsapp from '../../../assets/images/whatsapp.svg';
import IconWeb from '../../../assets/images/web.svg';
import {FormBlankConfig} from "../../modules/FormConfiguration/FormBlankConfig/FormBlankConfig.tsx";
import {useGetAccessToken} from "../../../hooks/useGetAccessToken.tsx";
import useGetPageId from "../../../hooks/useGetPageId.tsx";
import {UseFetchOneAssistant} from "../../../api/ApiCalls.tsx";
import {useTranslation} from "react-i18next";

type Channel = "Phone" | "Telegram" | "WhatsApp" | "Web";

interface AssistantViewSectionProps {}

export const AssistantViewSection: React.FC<AssistantViewSectionProps> = ()=>{

    //Definición de los estados de los selects
    const [selectedDetailOptionGlobal, setSelectedDetailOptionGlobal] = useState<string>('openai:gpt-3.5-turbo');
    const [selectedTranscriberGlobal, setSelectedTranscriberGlobal] = useState<string>('deepgram');
    const [selectedVoiceGlobal, setSelectedVoicelobal] = useState<string>('aws-polly');

    const optionsSelectPhone: string[] = [selectedDetailOptionGlobal, selectedTranscriberGlobal, selectedVoiceGlobal];
    const optionsSelect: string[] = [selectedDetailOptionGlobal];
    const { t } = useTranslation();

    const titleChannels = {
        Phone: {icon: IconPhone,name: t('channels.channel_phone')},
        Telegram: {icon:IconTelegram ,name:t('channels.channel_telegram')},
        WhatsApp: {icon:IconWhatsapp,name:t('channels.channel_whatsapp')},
        Web: {icon:IconWeb,name:t('channels.channel_web')},
    };
    const token = useGetAccessToken();
    const pageId = useGetPageId();

    const {data} = UseFetchOneAssistant(token, pageId);

    //Estado para la opción activa
    const [activeChannel, setActiveChannel] = useState<Channel>('Phone');
    const  handleChannelClick = (chanel:Channel)=> {
        setActiveChannel(chanel)
    }

    const handleSelectChange = (selectName:string, value:string):void => {
        switch (selectName) {
        case 'selectedDetailOption':
            setSelectedDetailOptionGlobal(value);
            break;
        case 'selectedTranscriber':
            setSelectedTranscriberGlobal(value);
            break;
        case 'selectedVoice':
            setSelectedVoicelobal(value);
            break;
        }
    }


    // Mapeo de contenido por canal
    const channelContent: Record<Channel,JSX.Element>={
        Phone: <CanaiaSettingsPhoneChannelSection data={data} selectedDetailOption={optionsSelectPhone}  activeChannel={activeChannel === 'Phone' ? 'Phone' : ''}/>,
        Telegram: <CanaiaSettingsTelegramChannelSection data={data} selectedDetailOption={optionsSelect}  activeChannel={activeChannel === 'Telegram' ? 'Telegram' : ''}/>,
        WhatsApp: <CanaiaSettingsWhatsAppChannelSection data={data} selectedDetailOption={optionsSelect}  activeChannel={activeChannel === 'WhatsApp' ? 'WhatsApp' : ''}/>,
        Web: <CanaiaSettingsWebChannelSection data={data} selectedDetailOption={optionsSelect}  activeChannel={activeChannel === 'Web' ? 'Web' : ''}/>
    }
    return (
        <div className="assistant-view-section-wrapper">
            <div className="metrics-card" style={{marginBottom:'10px'}}>
                <CanaiaTitleSimple title={t('metrics_pages.channels')}/>
                <div className="channels-options-wrapper">
                    {
                        Object.entries(titleChannels).map(([title, {icon,name}]) => (
                            <CanaiaButtonWithIcon
                                key={title}
                                title={name}
                                image={icon}
                                onClick={() => handleChannelClick(title as Channel)}
                                className={activeChannel === title ? "active" : ""}
                            />
                        ))
                    }
                </div>
                <div className="channel-content-wrapper">
                    {channelContent[activeChannel]}
                </div>
            </div>
            <div className="form-config-wrapper">
                <FormBlankConfig
                    handleSelectChangeGlobal={handleSelectChange}
                />
            </div>
        </div>
    )
}