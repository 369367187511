import {Column, Grid} from "@carbon/react";
import {AssistantCard} from "../../../../ui/components/AssistantCard/AssistantCard.tsx";
import {MainLayout} from "../../../../ui/layout/MainLayout.tsx";
import {useNavigate} from "react-router-dom";
import {Trans, useTranslation} from "react-i18next";


export const CatalogueAssistants = () => {

    const navigate = useNavigate();
    const {t} = useTranslation()

    const onNavigate = () => {
        navigate('/new-assistant')
    }

    return (
        <MainLayout>
            <Grid>
                <Column span={16}>
                    <h2 className={'grey-color-text assist-h2'}>
                        <Trans i18nKey="create_assistant.what_kind_of_assistant">
                            What kind of <span className="secondary-color">assistant</span> would you like to create?
                        </Trans>
                    </h2>
                </Column>
            </Grid>
            <Grid narrow={true} className={"assist-grid"}>
                <Column lg={4} md={4} sm={4}>
                    <AssistantCard
                        iconName={"DocumentBlank"}
                        type={t('assistants.blank')}
                        description={t('create_assistant.create_new_assistant')}
                        onClick={onNavigate}>
                    </AssistantCard>
                </Column>
                <Column lg={4} md={4} sm={4} className="inactive">
                    <AssistantCard
                        iconName={"Catalog"}
                        type={t('assistants.booking')}
                        //description="Make reservations for your business."
                        description={t('create_assistant.coming_soon')}
                        onClick={() => console.log('Booking')}>
                    </AssistantCard>
                </Column>
                <Column lg={4} md={4} sm={4} className="inactive">
                    <AssistantCard
                        iconName={"Restaurant"}
                        type={t('assistants.restaurant')}
                        //description="Make reservations for your restaurant and answer questions about your menu."
                        description={t('create_assistant.coming_soon')}
                        onClick={() => console.log('Restaurante')}>
                    </AssistantCard>
                </Column>
                <Column lg={4} md={4} sm={4} className="inactive">
                    <AssistantCard
                        iconName={"Hotel"}
                        type={t('assistants.hotel')}
                        //description="Make reservations for your hotel or meets the needs of your guests. "
                        description={t('create_assistant.coming_soon')}
                        onClick={() => console.log('Hotel')}>
                    </AssistantCard>
                </Column>
            </Grid>
        </MainLayout>
    )
}

export default CatalogueAssistants