import React, { useState } from 'react';
import { LineChart, ScaleTypes } from "@carbon/charts-react";
import './CanaiaChartLineMultiLine.scss';
import '@carbon/charts-react/styles.css';
import IconPhone from '../../../assets/images/phone.svg';
import IconTelegram from '../../../assets/images/telegram.svg';
import IconWhatsApp from '../../../assets/images/whatsapp.svg';
import IconWeb from '../../../assets/images/web.svg';
import {useTranslation} from "react-i18next";

type ChannelGroup = 'Phone' | 'Telegram' | 'WhatsApp' | 'Web';

interface CanaiaChartLineMultiLineProps {
    data: { group: string; date: string; value: number }[];
    colors: { [key in ChannelGroup]: string }; // Define el tipo de colors
}

const legends = {
    'Phone': {
        icon: IconPhone,
        label: 'Phone',
    },
    'Telegram': {
        icon: IconTelegram,
        label: 'Telegram',
    },
    'WhatsApp': {
        icon: IconWhatsApp,
        label: 'WhatsApp',
    },
    'Web': {
        icon: IconWeb,
        label: 'Web',
    },
};

export const CanaiaChartLineMultiLine: React.FC<CanaiaChartLineMultiLineProps> = ({ data, colors }) => {

    const {t} = useTranslation();

    // Inicializa los grupos visibles como todas las claves de las leyendas
    const initialVisibleGroups = Object.keys(legends).filter(group =>
        data.some(item => item.group === group && item.value > 0)
    );

    // Estado para controlar qué grupos son visibles en el gráfico
    const [visibleGroups, setVisibleGroups] = useState(initialVisibleGroups);

    // Alterna la visibilidad de los grupos
    const toggleGroupVisibility = (group: string) => {
        setVisibleGroups(prev => {
            const newVisibleGroups = prev.includes(group)
                ? prev.filter(g => g !== group) // Si ya está visible, lo ocultamos
                : [...prev, group]; // Si no está visible, lo añadimos

            // Filtrar grupos visibles que tengan datos
            const validVisibleGroups = newVisibleGroups.filter(group =>
                data.some(item => item.group === group && item.value > 0)
            );

            // Asegurarse de que al menos un grupo esté visible
            return validVisibleGroups.length > 0 ? validVisibleGroups : prev;
        });
    };

    // Filtrar los datos para mostrar solo los grupos visibles
    const filteredData = data.filter(item => visibleGroups.includes(item.group));

    // Filtrar series para incluir solo grupos visibles con datos
    const series = Object.keys(legends)
        .filter(group => visibleGroups.includes(group) && data.some(item => item.group === group && item.value > 0)) // Filtra grupos visibles con datos
        .map(group => ({
            group,
            data: data.filter(item => item.group === group), // Filtra los datos para cada grupo visible
        }))
        .filter(s => s.data.length > 0); // Asegurarse de que la serie tenga datos

    // Configuración del gráfico
    const options = {
        axes: {
            bottom: {
                title: t('metrics.date'),
                mapsTo: 'date',
                scaleType: ScaleTypes.TIME,
            },
            left: {
                title: t('metrics.messages'),
                mapsTo: 'value',
                scaleType: ScaleTypes.LINEAR,
            },
        },
        height: '400px',
        color: {
            scale: colors, // Asignación de colores
        },
        series, // Series de datos a mostrar
    };

    return (
        <div className="canaia-chart-multiline-wrapper">
            <div className="canaia-title-wrapper">
                <div className="chart-title">{t('metrics_pages.channels')}</div>
                <div className="custom-legend">
                    {Object.entries(legends).map(([key, { icon, label }]) => {
                        const hasData = data.some(item => item.group === key && item.value > 0);
                        if (!hasData) return null;
                        return (
                            <div
                                key={key}
                                className="legend-item"
                                onClick={() => toggleGroupVisibility(key)}
                                style={{
                                    cursor: 'pointer',
                                    opacity: visibleGroups.includes(key) ? 1 : 0.5,
                                }}
                            >
                                <img src={icon} alt={label} />
                                <span>{label}</span>
                            </div>
                        );
                    })}
                </div>
            </div>
            <LineChart data={filteredData} options={options} />
        </div>
    );
};
