import {useLogto} from '@logto/react';
import {useEffect} from 'react';
import {Outlet, useNavigate} from 'react-router-dom';

export const UseAuth = () => {

    const navigate = useNavigate()
    const { isAuthenticated, isLoading, signIn } = useLogto();
    
    useEffect(() => {
        if (!isAuthenticated && !isLoading) {
            navigate("/");
        }
    }, [isAuthenticated, isLoading, navigate, signIn]);

    return isAuthenticated ? <Outlet /> : <p>Necesita Iniciar Sesión</p>;
}

