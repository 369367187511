import React from 'react';
import {TextArea} from "@carbon/react";
import './CanaiaTextArea.scss';

interface CanaiaTextAreaProps {
    label?: string;
    id: string;
    value?: string;
    required?: boolean;
    placeholder?: string;
    disabled?: boolean;
    width?: string;
    className?: string;
    onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

export const CanaiaTextArea: React.FC<CanaiaTextAreaProps> = ({
    label,
    id,
    value,
    required = false,
    placeholder = "",
    disabled = false,
    width = '100%',
    className = "canaia-textarea-container",
    onChange,
})=>{
    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>)=>{
        // Obtiene el valor del texto ingresado en el textarea
        const value = event.target.value;
        // Expresión regular que valida si el contenido solo tiene letras, números, espacios y guiones
        const regex = /^[a-zA-Z0-9\s-]*$/;

        if(regex.test(value)){
            // Si es válido, llama a onChange con el valor actual
            if (onChange) {
                onChange(event); // Llama a onChange solo si el valor es válido
            }
        }
    }
    return (
        <div style={{maxWidth: width}} className={className}>
            <TextArea
                id={id}
                value={value}
                required={required}
                placeholder={placeholder}
                disabled={disabled}
                labelText={label}
                onChange={handleChange}
            />
        </div>
    )
}