//Definición de las claves de las opciones
import IconGreen from "../assets/images/robot-green.svg";
import IconYellow from "../assets/images/robot-yellow.svg";
import IconRed from "../assets/images/robot-red.svg";
import { t } from 'i18next';

const optionsTimeResponse = {
    Excellent:{icon:IconGreen, color:'green'},
    Good:{icon:IconYellow, color:'yellow'},
    Bad:{icon:IconRed, color:'red'},
} as const;

const channel_values = {
    'phone': '300',
    'telegram': '1500',
    'whatsApp' : '5000',
    'web': '100'
}

const llm_values = {
    "Claude 3 Haiku": 400,
    "Claude 3 Sonnet": 800,
    "Claude 3 Opus": 1200,
    "Claude 3.5 Sonnet": 800,
    "GPT 3.5 Turbo": 400,
    "GPT 4o": 800,
    "GPT 4o Mini": 400,
    "GPT 4 Turbo": 1000
};

const transcriber_values = {
    "Azure" : 1500,
    "Deepgram" : 100
}

const voice_values = {
    "AWS":300,
    "Azure":700
}

//fusionar todos los arreglos
type CombinedValue = {
    type: string;
    name: string;
    value: string | number;
};
//se combinaron los arreglos
function combineToArray(...objects: { [key: string]: string | number }[]): CombinedValue[] {
    const result: CombinedValue[] = [];

    // Itera sobre cada objeto pasado como parámetro
    objects.forEach((obj) => {
        const type = Object.keys(obj)[0].replace('_values', '');
        Object.entries(obj).forEach(([name, value]) => {
            result.push({ type, name, value });
        });
    });

    return result;
}

//función para transformar los datos
function formattedText  (text:string):string{
    //verficar si tiene dos puntos la cadena
    const afterColon = text.includes(":")? text.split(":")[1]:text;
    return afterColon
        .toLowerCase()
        .replace(/-/g, ' ')  // Reemplaza los guiones por espacios
        .replace(/\b\w/g, (char) => char.toUpperCase());  // Convierte la primera letra de cada palabra a mayúsculas
}
//función para transformar los datos
function formattedTextVoiceOption  (text:string):string{
    const textTransform: string = text.split("-")[0]
        .toLowerCase()
        .replace(/\b\w/g, (char) => char.toUpperCase());  // Convierte la primera letra de cada palabra a mayúsculas
    return textTransform.includes('Aws') ? textTransform.toUpperCase() : textTransform;
}

// Uso
const combinedObject = combineToArray(channel_values, llm_values, transcriber_values, voice_values);


// Obtiene las claves de los objetos
type OptionsQuality = keyof typeof optionsTimeResponse;


export const showOptionQuality = (
    value: number,
    firstRange: number,
    secondRange: number,
): {
    icon: string; color: string; name: string; key: OptionsQuality } | undefined => {

    if (value <= firstRange) {
        return {...optionsTimeResponse.Excellent,key: 'Excellent', name: t('channels.excellent')};
    }else if (value > firstRange && value < secondRange) {
        return {...optionsTimeResponse.Good,key: 'Good', name:t('channels.good')};
    }else if (value >= secondRange ){
        return {...optionsTimeResponse.Bad,key:'Bad', name:t('channels.bad')}
    }
    return undefined;
}

//funcion
export const validateOption = (activeChannel: string, nameOption:string[]) => {
    if (activeChannel === 'Phone') {
        return optionCheckQuality(nameOption);
    }else if (activeChannel === 'Telegram' || activeChannel === 'WhatsApp' || activeChannel === 'Web') {
        return optionCheckQuality(nameOption);
    }
    return 0;
}

const optionCheckQuality =(nameOption:string[]) =>{
    let total:number = 0;
    //Recorremos los elementos del arreglo
    nameOption.forEach((option, index)=>{
        let foundItem;
        if(index === 3){
            foundItem = combinedObject.reverse().find(item=> formattedTextVoiceOption(item.name) === formattedTextVoiceOption(option));
        }else {
            foundItem = combinedObject.find(item=> formattedText(item.name) === formattedText(option));
        }
        // validación al encontrarlo
        if(foundItem) {
            //console.log(`Nombre: ${foundItem.name}, Valor: ${foundItem.value}`);
            total += Number(foundItem.value);
        }else {
            //console.log(`Opción ${option} no encontrada en combinedObject.`);
        }
    });
    return total;
}