import {authStore} from "../store/authStore.tsx";
import {useEffect} from "react";
import {useParams} from "react-router-dom";

export const useGetPageId = () => {

    const {assistantId, setPageId} = authStore();
    const {pageId} = useParams();

    useEffect(() => {
        if (pageId && assistantId !== '' && assistantId !== pageId) {
            setPageId(pageId)
        }
    }, [assistantId, pageId, setPageId]);
    return pageId
}

export default useGetPageId;