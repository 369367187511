import React, {useEffect} from 'react';
import './CanaiaToastNotification.scss';
import {ToastNotification} from "@carbon/react";


interface Props {
    message?: string;
    type: 'error' | 'info' | 'info-square' | 'success' | 'warning' | 'warning-alt';
    duration?: number;
    onClose: () => void;
}

export const CanaiaToastNotification : React.FC<Props> = ({message,type,duration=3000,onClose}) => {

    useEffect(() => {
        const timer = setTimeout(()=>{
            onClose();
        }, duration);
        return ()=> clearTimeout(timer);
    }, [onClose,duration]);

    return (
        <ToastNotification
            kind={type}
            title={message}
            onCloseButtonClick={onClose}
            aria-hidden={false}
            lowContrast={true}
        />
    )
};