import React, { useEffect, useState } from 'react';
import './CanaiaInnerChannelTelegram.scss';
import { ChannelType } from "../ChannelsViewSection/ChannelsViewSection.tsx";
import { CanaiaButton } from "../../atoms/CanaiaButton/CanaiaButton.tsx";
import { Loading, Modal } from "@carbon/react";
import { ChannelTable } from "../ChannelTable/ChannelTable.tsx";
import DataTable, { TableColumn } from 'react-data-table-component';
import { CanaiaFormErrorStatus } from "../../atoms/CanaiaFormErrorStatus/CanaiaFormErrorStatus.tsx";
import { TrashCan } from "@carbon/icons-react";
import { authStore } from "../../../store/authStore.tsx";
import { UseRemoveAssistantChannel } from "../../../api/ApiCalls.tsx";
import { CanaiaToastNotification } from "../../atoms/CanaiaToastNotification/CanaiaToastNotification.tsx";
import {useTranslation} from "react-i18next";


// Define el tipo Bot
interface Bot {
    id: number;
    botName: string | undefined;
}

interface Props {
    channels: ChannelType[];
    handleDelete: () => void;
}

export const CanaiaInnerChannelTelegram: React.FC<Props> = ({ channels, handleDelete}) => {
    const { accessToken } = authStore();
    const [open, setOpen] = useState(false);
    const [pending, setPending] = useState(true);
    const [botNames, setBotNames] = useState<Bot[]>([]);
    const updateMutation = UseRemoveAssistantChannel(accessToken);
    const {t} = useTranslation();

    // Funciones para toast
    const [showToast, setShowToast] = useState(false);
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState<'error' | 'info' | 'info-square' | 'success' | 'warning' | 'warning-alt'>('success');

    const handleClose = () => {
        setOpen(false);
    }

    const handleRemoveChannel = (id: number) => {
        updateMutation.mutate(id.toString(), {
            onSuccess: () => {
                setMessage(t('toasts.success_channel_removed'));
                setMessageType('success');
                setShowToast(true);
                handleDelete();
            },
            onError: (error) => {
                console.error(error);
                setMessage(t('toasts.error_removing_channel'));
                setMessageType('error');
                setShowToast(true);
            },
        });
    }

    useEffect(() => {
        const updateBots = () => {
            const bots = channels
                .map(channel => ({
                    id: channel.id,
                    botName: channel.bot
                }))
                .filter(bot => bot.botName !== undefined);

            setBotNames(bots);
            setPending(false);
        };

        setPending(true);
        updateBots();
    }, [channels]);

    const formatNameAssistant = (text: string | undefined) => {
        if (!text) return '';
        return text.replace(/_/g, ' ')
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    const columns: TableColumn<Bot>[] = [
        {
            name: 'Bot Name',
            selector: row => formatNameAssistant(row.botName),
            cell: row => {
                const botNameFormated = formatNameAssistant(row.botName);
                const linkHTML = `<a href="https://t.me/${row.botName}" target="_blank" rel="noopener noreferrer nofollow">${botNameFormated}</a>`;
                return <div dangerouslySetInnerHTML={{ __html: linkHTML }} />;
            },
            sortable: true,
        },
        {
            name: 'Actions',
            cell: (dataRow) => {
                // Imprimir el contenido de dataRow en la consola// console.log('Contenido de dataRow:', dataRow);
                return (
                    <div
                        className={'action-delete'}
                        aria-label={`Removed channel ${dataRow.id}`}
                        style={{
                            alignItems: 'center',
                            color: '#94A3B8',
                            cursor: 'pointer',
                            display: 'flex',
                            justifyContent: 'right',
                            width: '100%',
                        }}
                        onClick={() => handleRemoveChannel(dataRow.id)}
                    >
                        <TrashCan
                            size={21}
                            className={'icon-danger-color'}
                            aria-hidden="true"
                        />
                    </div>
                );
            },
            sortable: false, // Si no quieres que esta columna sea sortable
        }
    ];

    return pending ? (
        <Loading className={"bots-loading"} withOverlay={false} />
    ) : (
        <div className="canaia-inner-channel-telegram-wrapper">
            {showToast && (
                <CanaiaToastNotification
                    message={message}
                    type={messageType}
                    onClose={() => setShowToast(false)}
                />
            )}
            <CanaiaButton kind={'secondary'} onClick={() => setOpen(true)} >{t('buttons.request_bot')}</CanaiaButton>
            <Modal className={'modal-channel'} passiveModal open={open} onRequestClose={() => setOpen(false)}>
                <ChannelTable type={'telegram'} onClose={handleClose} />
            </Modal>
            {botNames.length === 0 ? (
                <CanaiaFormErrorStatus text={t('channels.no_bots_available')} />
            ) : (
                <>
                    <div className="channel-inner-options-wrapper">
                        <DataTable
                            columns={columns}
                            data={botNames}  // Aquí pasas 'botNames' como Bot[]
                            pagination={false}
                        />
                    </div>
                </>
            )}
        </div>
    );
}
