import { useContext } from 'react';
import { AuthContext, AuthContextType } from '../context/AuthContext';

//Hook que expone isAuthenticated y userLanguage
export const useCheckAuth = ():AuthContextType => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useCheckAuth debe usarse dentro de un AuthProvider');
    }
    return context;
};
