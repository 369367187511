//
//
//  Pricing
//
//

import {useLogto} from "@logto/react"
import {useEffect, useState} from "react"
import {MainLayout} from "../../ui/layout/MainLayout.tsx";
import {Loading} from "@carbon/react";
import {useTranslation} from "react-i18next";


const LoadPotioScript = () => {
    // We need to load the Potio script in order to use the Potio web component
    useEffect(() => {
        const script = document.createElement('script')
        script.src = "https://webcomponent.potio.cc/index.js"
        script.async = true
        document.body.appendChild(script)

        return () => {
            document.body.removeChild(script)
        }
    }, [])

    return null
}


function Pricing() {
    const [isLoading, setIsLoading] = useState(true)
    const {getAccessToken, getIdTokenClaims} = useLogto()
    const [userId, setUserId] = useState<string | undefined>(undefined)
    const [userEmail, setUserEmail] = useState<string | undefined>(undefined)
    const [stripeCustomer, setStripeCustomer] = useState<string | undefined>(undefined)
    const {t} = useTranslation()

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = await getAccessToken(import.meta.env.VITE_LOGTO_API_ID);
                const response = await fetch(`${import.meta.env.VITE_API_ENDPOINT}/api/user/subscription`, {
                    method: "GET",
                    headers: { Authorization: `Bearer ${token}` }
                });

                const data = await response.json();
                setStripeCustomer(data.stripe_customer_id);

                const claims = await getIdTokenClaims();
                setUserId(claims?.sub);
                setUserEmail(claims?.email || undefined);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData().then();
    }, [getAccessToken, getIdTokenClaims]);

    if (isLoading) {
        return (
            <MainLayout>
                <h2 style={{marginBottom: '2rem'}}>{t('menu.subscription')}</h2>
                <Loading style={{margin: '0 auto'}} className="pricing-loading" withOverlay={false}/>
            </MainLayout>
        )
    }

    return (
        <>
            <MainLayout>
                <h2 style={{marginBottom: '2rem'}}>{t('menu.subscription')}</h2>

                {stripeCustomer ? (
                    <potio-pricing-table
                        customer={stripeCustomer}
                        client-reference-id={userId}
                        action="payment"
                        data-key={import.meta.env.VITE_POTIO_PRICING_TABLE_KEY}
                    ></potio-pricing-table>
                ) : (
                    <potio-pricing-table
                        customer-email={userEmail}
                        client-reference-id={userId}
                        action="payment"
                        data-key={import.meta.env.VITE_POTIO_PRICING_TABLE_KEY}
                    ></potio-pricing-table>
                )}

                <LoadPotioScript/>
            </MainLayout>
        </>
    )
}

export default Pricing
