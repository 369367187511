import React, { useEffect } from 'react';
import {useCheckAuth} from "../hooks/useCheckAuth.tsx";
import {initializeI18n} from "./i18n.ts";

//Consulta userLenguage y lo envía a i18
export const LanguageInitializer: React.FC = () => {
    const { userLanguage } = useCheckAuth();

    useEffect(() => {
        initializeI18n(userLanguage);
    }, [userLanguage]);

    return null;
};

export default LanguageInitializer;
