import {Navigate, Route, Routes} from "react-router-dom";
import {Home} from "../pages/Home";
import Pricing from "../pages/Pricing";
import {UseAuth} from "../hooks/useAuth.tsx";
import SignInCallback from "../pages/SignInCallback.tsx";
import {ViewAssistants} from "../pages/Home/views/ViewAssistants";
import {CatalogueAssistants} from "../pages/Home/views/CatalogueAssistants";
import {Metrics} from "../pages/Metrics";
import {PlaygroundShared} from "../pages/PlaygroundShared/PlaygroundShared.tsx";
import {NewAssistant} from "../pages/Home/views/NewAssistant/NewAssistant.tsx";

export const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/sign-in-callback" element={<SignInCallback/>}/>
            <Route path="/my-assistant" element={<PlaygroundShared />} />
            <Route element={<UseAuth />}>
                <Route path={"subscription"} element={<Pricing />}></Route>
                <Route path={"new-assistant"} element={<NewAssistant />}/>
                <Route path={"choose-assistant"} element={<CatalogueAssistants />}/>
                <Route path={"assistants"} element={<ViewAssistants />}/>
                <Route path={"global/:pageId"} element={<Metrics />}/>
            </Route>
            <Route path="*" element={<Navigate to="/" replace />} /> {/* Redirige a la ruta de inicio */}
        </Routes>
    )
}
