import {useEffect, useMemo, useState} from "react";
import {Assistant} from "../../../../api/ApiCalls.tsx";
import DataTable, {TableColumn} from "react-data-table-component";
import {Column, Grid, Loading, Search} from "@carbon/react";
/*import {Checkmark} from "@carbon/icons-react";*/
import {useNavigate} from "react-router-dom";
import './ViewAssistants.scss';
import {useTranslation} from "react-i18next";

interface Props {
    data: Assistant[]; // Tipo más específico
}

export const AssistantsGrid = ({data}: Props) => {
    const [rowRecords, setRowRecords] = useState<Assistant[]>([]);
    const [pending, setPending] = useState(true);
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        if (data) {
            const normalizedData = data.map((assistant) => ({
                ...assistant,
                name: assistant.name.toLowerCase(),
            }));
            setRowRecords(normalizedData); // Se envía a minúscula para estandarizar ordenamiento.
        }
        setPending(false);
    }, [data]);

    const handleSearch = (e: { target: HTMLInputElement }) => {
        const searchValue = e.target.value.toLowerCase();

        if (searchValue === '') {
            // Si el campo está vacío, restablece los datos originales normalizados
            setRowRecords(data.map((assistant) => ({
                ...assistant,
                name: assistant.name.toLowerCase(),
            })));
        } else {
            // Filtra `data` para buscar coincidencias y así actualizar cada vez que cambia el input
            const filteredRows = data
                .map((assistant) => ({
                    ...assistant,
                    name: assistant.name.toLowerCase(),
                }))
                .filter((row: Assistant) =>
                    row.name.includes(searchValue)
                );
            setRowRecords(filteredRows);
        }
    };

    const handleNavigation = (pageId: number | undefined) => {
        navigate(`/global/${pageId}`);
    };

    /*const statusFunction = () => {
        const isDeployed = row.type === 'deployed';
        const statusClass = isDeployed ? 'success-color success-color-bg' : 'danger-color danger-color-bg';
        const Icon = isDeployed ? Checkmark : WarningAlt;
        const statusText = isDeployed ? 'Deployed' : 'Suspended';

        const statusClass = 'success-color success-color-bg';
        const Icon = Checkmark;
        const statusText = 'Deployed';

        return (
            <div className={`status-row ${statusClass}`}>
                <Icon size={23} /> {statusText}
            </div>
        );
    };*/

    // Definición de columnas directamente
    const columns: TableColumn<Assistant>[] = useMemo(() => [

        {
            name: t('assistants.assistant_name'),
            selector: row => row.name,
            sortable: true,
        },
        /*{
            name: "Status",
            cell: () => statusFunction(),
        },*/
        {
            name: t('assistants.type'),
            selector: row => row.type,
        }
    ], [t]);

    return pending ? (
        <Loading className={'table-loading'} withOverlay={false}/>
    ) : (
        <>
            <Grid fullWidth>
                <Column className={'search-row '} lg={8} md={4} sm={4}>
                    <Search
                        onChange={(e) => handleSearch(e)}
                        labelText={''}
                        placeholder={t('assistants.search')}
                        size={'md'} />
                </Column>
            </Grid>
            <DataTable
                className={'assistant-grid'}
                columns={columns}
                data={rowRecords}
                pagination={true}
                onRowClicked={(row) => handleNavigation(row.id)}
            />
        </>
    );
};
