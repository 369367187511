import './PlaygroundShared.scss';
import image from '../../assets/images/canaia_logo_white.svg'
import {PlaygroundChat} from "../../ui/components/PlayGroundChat/PlaygroundChat.tsx";


export const PlaygroundShared = () => {

    return (

        <div className={'playground-shared'}>
            <div className={'playground-shared__header'}>
                <div className="image-wrapper">
                    <img src={image} alt={'Canaia Assist Logo'}/>
                </div>
            </div>
            <PlaygroundChat></PlaygroundChat>
        </div>
        
    )
}