import React, {useContext} from "react";
import {Column, Grid} from "@carbon/react";
import {AssistantHeader} from "../modules/AssistantHeader/AssistantHeader.tsx"
import './layout.scss';
import {useLocation, useNavigate} from "react-router-dom";
import {AuthContext} from "../../context/AuthContext.tsx";
import {SubscriptionBanner} from "../components/SubscriptionBanner/SubscriptionBanner.tsx";

interface Props {
    children: React.ReactNode
}

export const MainLayout: React.FC<Props> = ({children}) => {

    const location = useLocation();
    const specialRoutes = ['/assistants', '/subscription'];
    const pageClass = specialRoutes.includes(location.pathname) ? 'assistant-main' : 'regular-main';
    const {subscriptionData} = useContext(AuthContext) || {}; // Asegura que `AuthContext` no sea null
    const navigate = useNavigate()

    // Comprobación para mostrar el banner de suscripción:
    const showSubscriptionBanner = !subscriptionData?.subscription ||
        !subscriptionData.subscription.type || // Valida si el tipo de suscripción no está definido
        new Date() > new Date(subscriptionData.subscription.period_end); // Compara fechas de expiración


    const handleRenew = () => {
        navigate("/subscription");
    };

    return (
        <>
            <AssistantHeader page={'white-header'}></AssistantHeader>
            {/* Mostrar el banner de suscripción si el periodo ha expirado */}
            {showSubscriptionBanner && (
                <SubscriptionBanner
                    onRenew={handleRenew}
                />
            )}
            <main className={`content-options-assistant-wrapper ${pageClass} ${showSubscriptionBanner ? 'with-banner' : ''}`}>
                <Grid className={'app-wrapper'} fullWidth={true}>
                    <Column className={'app-grid-table'} sm={4} md={8} lg={16}>
                        {children}
                    </Column>
                </Grid>
            </main>
        </>
    )
}
