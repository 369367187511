import React from 'react';
import './CanaiaInternalChannelsMenu.scss';
import {CanaiaButtonWithIcon} from "../../atoms/CanaiaButtonWithIcon/CanaiaButtonWithIcon.tsx";
import IconPhoneChannel from '../../../assets/images/phone_channel.svg';
import IconTelegramChannel from '../../../assets/images/telegram_channel.svg';
import IconWhastAppChannel from '../../../assets/images/whatsapp_channel.svg';
import {CanaiaTitleSimple} from "../../atoms/CanaiaTitleSimple/CanaiaTitleSimple.tsx";
import {useTranslation} from "react-i18next";

// Se definen las opciones del Menu
export type ChannelOptions = "Phone" | "Telegram" | "WhatsApp";

interface Props {
    localActiveOption?:ChannelOptions;
    handleClick:(option: ChannelOptions)=>void;
}

export const CanaiaInternalChannelsMenu: React.FC<Props>= ({localActiveOption,handleClick}) => {

    const {t} = useTranslation();

    // Array con opciones de menu e iconos
    const channelOptions=[
        {icon: IconPhoneChannel, text: 'Phone'},
        {icon: IconTelegramChannel, text: 'Telegram'},
        {icon: IconWhastAppChannel, text: 'WhatsApp'}
    ]

    return (
        <div className="canaia-internal-channels-menu">
            <CanaiaTitleSimple title={t('metrics_pages.channels')}/>
            <div className="canaia-internal-channels-wrapper">
                {channelOptions.map((option, index) => (
                    <CanaiaButtonWithIcon
                        key={index}
                        title={option.text}
                        image={option.icon}
                        onClick={()=>handleClick(option.text as ChannelOptions)}
                        isSelected={ localActiveOption === option.text }
                        className={''}
                    />
                ))}
            </div>
        </div>
    );
}

