import './SubscriptionBanner.scss';
import {useTranslation} from "react-i18next";

interface SubscriptionBannerProps {
    onRenew?: () => void;
}

export const SubscriptionBanner: React.FC<SubscriptionBannerProps> = ({
    onRenew,
}) => {

    const {t} = useTranslation();

    return (

        <div className="subscription-banner">
            <p>
                {t('banner.no_active')}{' '}
                <span
                    className="subscription-banner-link"
                    onClick={onRenew}
                    role="link"
                    tabIndex={0}
                    style={{color: '#fff', cursor: 'pointer', textDecoration: 'underline'}}
                >
                    {t('banner.renew_payment')}
                </span>{' '}
            </p>
        </div>

    )
}
