import React from "react";
import './CanaiaSettingsPhoneChannelSection.scss';
import {CanaiaTitleSimple} from "../../atoms/CanaiaTitleSimple/CanaiaTitleSimple.tsx";
import {CanaiaSubtitleSimple} from "../../atoms/CanaiaSubtitleSimple/CanaiaSubtitleSimple.tsx";
import {CanaiaTextWithIconColor} from "../../atoms/CanaiaTextWithIconColor/CanaiaTextWithIconColor.tsx";
import {CanaiaPogressBar} from "../../atoms/CanaiaPogressBar/CanaiaPogressBar.tsx";
//Import iconos response time
/*import IconGreen from "../../../assets/images/robot-green.svg";
import IconYellow from "../../../assets/images/robot-yellow.svg";
import IconRed from "../../../assets/images/robot-red.svg";*/
import IconClock from "../../../assets/images/clock.svg";
import {showOptionQuality, validateOption} from "../../../helpers/showOptionQuality.ts";
import {Assistant} from "../../../api/ApiCalls.tsx";
import {useTranslation} from "react-i18next";


interface CanaiaSettingsPhoneChannelSection {
    data: Assistant | undefined
    activeChannel: string;
    selectedDetailOption: string[]
}

export const CanaiaSettingsPhoneChannelSection: React.FC<CanaiaSettingsPhoneChannelSection> = ({activeChannel,selectedDetailOption}) => {
    const {t} = useTranslation();
    //Arreglo de opciones que se van a buscar los valores
    const arrayOptions:string[] = [activeChannel,...selectedDetailOption];
    const value: number| undefined= validateOption(activeChannel,arrayOptions)
    const minValue:number = 2000;
    const maxValue: number = 4000;
    //Se pasó a una función en helpers
    const optionSelected = showOptionQuality(value, minValue,maxValue);

    // Mensaje con valor a mostrar
    const textScoreQuality = `- ${value} ms`;

    return (
        <div className="canaia-setting-channel-section">
            <div className="response-time-wrapper">
                <div className="information-wrapper">
                    <div className="titles-wrapper">
                        <CanaiaTitleSimple title={t('channels.response_time')}/>
                        <CanaiaSubtitleSimple
                            title={t('channels.time_it_takes')}/>
                    </div>
                    <div className="quality-score-wrapper">
                        <CanaiaSubtitleSimple
                            title={t('channels.quality_score')}/>
                        <div className="quality-score-options">
                            {optionSelected && (
                                <CanaiaTextWithIconColor
                                    icon={optionSelected.icon}
                                    text={t(optionSelected?.name ?? '')}
                                    color={optionSelected.color}/>
                            )}
                        </div>
                    </div>
                </div>
                <div className="progress-bar-wrapper">
                    <CanaiaTextWithIconColor icon={IconClock} text={textScoreQuality} color={'green-b'}/>
                    <CanaiaPogressBar
                        maxValue={maxValue}
                        className="test-class"
                        value={value} size='big'
                        type='inline'
                        helperText="HelperText" hideLabel={true} status='active' label='Progress'/>
                </div>
            </div>
        </div>
    );
}