import React from "react";
import './PlaygroundViewSection.scss';
import {PlayGroundPhone} from "../PlayGroundPhone/PlayGroundPhone.tsx";
import {Loading} from "@carbon/react";
import {CanaiaFormErrorStatus} from "../../atoms/CanaiaFormErrorStatus/CanaiaFormErrorStatus.tsx";
import {UseFetchWebToken} from "../../../api/ApiCalls.tsx";
import useGetPageId from "../../../hooks/useGetPageId.tsx";
import {useGetAccessToken} from "../../../hooks/useGetAccessToken.tsx";
import {PlaygroundChat} from "../PlayGroundChat/PlaygroundChat.tsx";

interface PlaygroundViewSectionProps {}

export const PlaygroundViewSection: React.FC<PlaygroundViewSectionProps> = () => {

    const pageId = useGetPageId();
    const accessToken = useGetAccessToken();
    const webToken = UseFetchWebToken(accessToken, pageId);

    if (webToken.isLoading) {
        return <Loading active={true} className={''} small={false} withOverlay={false}/>;
    }

    if (webToken.isError) {
        return <CanaiaFormErrorStatus text={'Ocurrió un error'}/>;
    }

    return (
        <div className="playground-view-section-wrapper metrics-card">
            <PlayGroundPhone></PlayGroundPhone>
            <PlaygroundChat webToken={webToken.data.token}></PlaygroundChat>
        </div>
    )
}