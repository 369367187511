import React from "react";
import './CanaiaFormErrorStatus.scss';

interface CanaiaFormErrorStatusProps {
    text: string;
}

export const CanaiaFormErrorStatus: React.FC<CanaiaFormErrorStatusProps> = ({
    text
})=>{
    return (
        <div className="canaia-status-error">
            {text}
        </div>
    )
}