import axios, {AxiosError, AxiosResponse} from 'axios';
import {useMutation, useQuery} from "@tanstack/react-query";
import apiClient from "./ApiClient.ts";
import createApiClient from "./ApiClient.ts";
import {useLogto} from "@logto/react";

export interface Assistant {
    id?: number
    name: string
    type: string
    transcriber_type?: string
    transcriber_endpoint_ms?: number
    voice_type?: string
    llm_type?: string
    llm_system_prompt?: string
    llm_temperature?: number
}

export interface FetchData {
    items: [],
    page: number,
    pages: number,
    size: number,
    total: number
}

export interface UpdateAssistant {
    name?: string
    transcriber_type?: string
    transcriber_endpoint_ms?: number,
    voice_type?: string
    type?: string,
    llm_type?: string,
    llm_system_prompt?: string,
    llm_temperature?: number
}

export interface Messages {
    items: []
}

export interface ConversationsInfo {
    channel?: string,
    created_at: string,
    id: number
}

export interface ChatSent {
    chat_id: string,
    "message": string | string[]
}

export interface ChatReceived {
    chat_id: string,
    "response": string | string[]
}

export interface AssistantResponse {
    id: string;
    name: string;
}

export interface PhoneObject {
    phonenumber: string
}

const QUERY_GET_ASSISTANT = ['GetAssistant'];
const QUERY_GET_ONE_ASSISTANT = ['GetOneAssistant'];
const QUERY_POST_ASSISTANT = ['PostAssistant'];
const QUERY_UPDATE_ASSISTANT = ['UpdateAssistant'];
const QUERY_DELETE_ASSISTANT = ['DeleteAssistant'];
const QUERY_GET_CHANNELS = ['FetchChannels'];
const QUERY_GET_CONVERSATIONS = ['FetchConversations'];
const QUERY_GET_CONVERSATIONS_INFO = ['FetchConversationsInfo'];
const QUERY_DELETE_CONVERSATION = ['DeleteAssistant'];
const QUERY_GET_MESSAGES = ['FetchMessages'];
const QUERY_GET_MESSAGES_STATS = ['FetchMessagesStats'];
const QUERY_GET_CONVERSATIONS_STATS = ['FetchConversationsStats'];
const QUERY_GET_AVAILABLE_CHANNELS = ['FetchAvailableChannels'];
const QUERY_POST_CHANNEL_TO_ASSISTANT = ['PostChannelToAssistant'];
const QUERY_POST_REMOVE_CHANNEL_TO_ASSISTANT = ['postRemoveChannelToAssistant'];
const QUERY_CHECK_CALL = ['ChechCall'];
const QUERY_REQUEST_CALL = ['RequestCall'];
const QUERY_WEB_CHAT = ['WebChat'];
const QUERY_GET_WEB_TOKEN = ['WebChatToken'];

const fetchRequest = async (token: string, url: string, signOut: () => Promise<void>): Promise<AxiosResponse> => {
    const apiClient = createApiClient(token, signOut); // Crea el cliente con el token
    return await apiClient.get(url).then(resp => {
        return resp;
    });
};

const checkCall = async (token: string, id: string | undefined) => {

    try {
        return await apiClient(token).head(`/api/assistants/${id}/calls`);
    } catch (error) {
        console.error("Bot is not available to call", error);
        throw error; // Puedes manejar el error o dejar que lo maneje el código que llama a esta función
    }
}

const requestCall = async (token: string, id: string | undefined, phoneNumber: PhoneObject) => {

    try {
        return await apiClient(token).post(`/api/assistants/${id}/calls`, phoneNumber);
    } catch (error) {
        console.error("Bot is not available to call", error);
        throw error; // Puedes manejar el error o dejar que lo maneje el código que llama a esta función
    }
}

const postChatMessage = async (token: string, chatBody: ChatSent | undefined) => {

    if (!chatBody) {
        throw new Error("chatBody is undefined");
    }

    try {
        return await apiClient(token).post('/webhooks/web', chatBody, {timeout: 2 * 60 * 1000, signal: AbortSignal.timeout(2 * 60 * 1000)});
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const axiosError = error as AxiosError;
            console.error("Response status:", axiosError.response?.status);
            console.error("Error message:", axiosError.message);
            console.error("Error response data:", axiosError.response?.data);
        } else {
            console.error("Unexpected error:", error);
        }
        throw error;
    }

}

const postAssistant = async (newData: Assistant, token: string | void) => {

    if (token) {
        try {
            //return await apiClient(token).post(`/api/user/assistants`, newData); // Devolvemos la respuesta si es necesario
            const response: AxiosResponse<AssistantResponse> = await apiClient(token).post(`/api/user/assistants`, newData);
            return response.data; // Retorna solo la data que contiene el ID
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw error; // Lanzamos el error de Axios
            }
            throw new Error("An unexpected error occurred");
        }
    } else {
        throw new Error("Token is missing");
    }
}

const postChannelToAssistant = async (token: string, assistantId: string | undefined, channelId: string) => {

    try {
        return await apiClient(token).post(`/api/assistants/${assistantId}/channels/${channelId}`);
    } catch (error) {
        console.error("Error posting channel to assistant:", error);
        throw error; // Puedes manejar el error o dejar que lo maneje el código que llama a esta función
    }

};

const postRemoveChannelToAssistant = async (token: string, channelId: string) => {

    try {
        return await apiClient(token).post(`/api/channels/${channelId}/release`);
    } catch (error) {
        console.error("Error removing channel to assistant:", error);
        throw error; // Puedes manejar el error o dejar que lo maneje el código que llama a esta función
    }
};

const updateAssistant = async (newData: UpdateAssistant, token: string, id: string | undefined) => {
    await apiClient(token).patch(`/api/assistants/${id}`, newData).then(resp => resp)
}

const deleteAssistant = async (token: string, id: string | undefined) => {
    return await apiClient(token).delete(`/api/assistants/${id}`).then(resp => resp);
}

const deleteConversation = async (token: string, id: string) => {
    return await apiClient(token).delete(`/api/conversations/${id}`).then(resp => resp);
}

const fetchChannels = async (token: string, id: string | undefined): Promise<AxiosResponse<FetchData>> => {
    return await apiClient(token).get<Assistant, never>(`/api/assistants/${id}/channels`).then(resp => resp);
}

const fetchConversationsInfo = async (token: string, id: string | undefined): Promise<AxiosResponse<ConversationsInfo>> => {
    return await apiClient(token).get(`/api/conversations/${id}`).then(resp => resp);
}

const fetchMessages = async (token: string, id: string | undefined): Promise<AxiosResponse<FetchData>> => {
    return await apiClient(token).get<FetchData>(`/api/conversations/${id}/messages`).then(resp => resp);
}

const fetchMessagesStats = async (token: string, id: string | undefined, from: string, to: string): Promise<AxiosResponse<Messages, never>> => {
    return await apiClient(token).get<Messages, never>(`/api/assistants/${id}/messages/stats?from=${from}&to=${to}`).then(resp => resp);
}

const fetchConversationsStats = async (token: string, id: string | undefined, from: string, to: string): Promise<AxiosResponse<Messages, never>> => {
    return await apiClient(token).get<Messages, never>(`/api/assistants/${id}/conversations/stats?from=${from}&to=${to}`).then(resp => resp);
}

// Fetch All assistants
export const UseFetchAssistants = (token: string) => {

    const { signOut } = useLogto(); // Obtén la función signOut aquí

    return useQuery({
        queryKey: [QUERY_GET_ASSISTANT, token],
        queryFn: async () => {
            try {
                const {data} = await fetchRequest(token, `/api/user/assistants`, signOut);
                return data;
            } catch (error) {
                throw new Error("Error fetching assistants");
            }
        },
        enabled: !!token,
        staleTime: 2000
    });

};

// Fetch One assistant
export const UseFetchOneAssistant = (token: string, id: string | undefined) => {

    const { signOut } = useLogto(); // Obtén la función signOut aquí

    return useQuery({
        queryKey: [QUERY_GET_ONE_ASSISTANT, token, id],
        queryFn: async (): Promise<Assistant | never> => {
            try {
                const {data} = await fetchRequest(token, `/api/assistants/${id}`, signOut);
                return data
            } catch (error) {
                throw new Error("Error fetching assistants");
            }
        },
        enabled: !!token && !!id,
    });

}

//Fetch Available Channels
export const UseFetchAvailableChannels = (token: string, channel: string) => {

    const { signOut } = useLogto(); // Obtén la función signOut aquí

    return useQuery({
        queryKey: [QUERY_GET_AVAILABLE_CHANNELS, channel],
        queryFn: async (): Promise<FetchData | never> => {
            try {
                const {data} = await fetchRequest(token, `/api/channels/${channel}/available`, signOut);
                return data
            } catch (error) {
                throw new Error("Error fetching channels");
            }
        }
    })
}

//Create Assistant
export const UsePostAssistant = (token: string) => {

    /*const { signOut } = useLogto(); // Obtén la función signOut aquí*/

    return useMutation({
        mutationFn: (newData: Assistant) => postAssistant(newData, token),
        mutationKey: QUERY_POST_ASSISTANT,
        retry: 3,
    });

}

// Update Assistant
export const UseUpdateAssistant = (token: string, id: string | undefined) => {

    /*const { signOut } = useLogto(); // Obtén la función signOut aquí*/

    return useMutation({
        mutationKey: QUERY_UPDATE_ASSISTANT,
        mutationFn: (updateData: UpdateAssistant) => updateAssistant(updateData, token, id),
        retry: 2,
    });

}

// Delete Assistant
export const UseDeleteAssistant = (token: string, id: string | undefined) => {

    /*const { signOut } = useLogto(); // Obtén la función signOut aquí*/

    return useMutation({
        mutationKey: QUERY_DELETE_ASSISTANT,
        mutationFn: () => deleteAssistant(token, id),
        retry: 3
    })

}

// Delete Conversations
export const UseDeleteConversation = (token: string) => {

    /*const { signOut } = useLogto(); // Obtén la función signOut aquí*/

    return useMutation({
        mutationKey: QUERY_DELETE_CONVERSATION,
        mutationFn: (id: string) => deleteConversation(token, id),
        retry: 3
    })

}

//Fetch Channels from Assistant
export const UseFetchChannels = (token: string, id: string | undefined) => {

    /*const { signOut } = useLogto(); // Obtén la función signOut aquí*/

    return useQuery({
        
        queryKey: QUERY_GET_CHANNELS,
        queryFn: async () => {
            const {data} = await fetchChannels(token, id);
            return data;
        },
        enabled: !!token && !!id,
        staleTime: 0,
        refetchOnWindowFocus: true, // Refresca datos al enfocar la ventana

    })

}

//Add Channel to Assistant
export const UsePutAssistantChannel = (token: string, assistantId: string | undefined) => {

    /*const { signOut } = useLogto(); // Obtén la función signOut aquí*/

    return useMutation({
        mutationKey: [QUERY_POST_CHANNEL_TO_ASSISTANT],
        mutationFn: (channelId: string,) => postChannelToAssistant(token, assistantId, channelId),
        retry: 2,
    })

}

//Remove Channel to Assistant
export const UseRemoveAssistantChannel = (token: string) => {
    return useMutation({
        mutationKey: [QUERY_POST_REMOVE_CHANNEL_TO_ASSISTANT],
        mutationFn: (channelId: string,) => postRemoveChannelToAssistant(token,channelId),
        retry: 2,
    })

}
//Fetch Conversations from Assistant
export const UseFetchConversations = (token: string, id: string | undefined) => {

    const { signOut } = useLogto(); // Obtén la función signOut aquí


    return useQuery({
        queryKey: [QUERY_GET_CONVERSATIONS, token],
        queryFn: async () => {
            try {
                return await fetchRequest(token, `/api/assistants/${id}/conversations`, signOut);
            } catch (error) {
                throw new Error("Error fetching assistants");
            }
        },
        staleTime: 1000,
        enabled: !!token && !!id,
    });

}

//Fetch Conversations Stats from Assistant
export const UseFetchConversationStats = (token: string, id: string | undefined, from: string, to: string) => {

    /*const { signOut } = useLogto(); // Obtén la función signOut aquí*/

    return useQuery({
        queryKey: [QUERY_GET_CONVERSATIONS_STATS, token, id, from, to],
        queryFn: async () => {
            const {data} = await fetchConversationsStats(token, id, from, to);
            return data;
        },
        staleTime: 1000,
        enabled: !!token && !!id && !!from && !!to
    })
}

//Fetch Messages from Assistant
export const UseFetchMessages = (token: string, id: string | undefined) => {

    /*const { signOut } = useLogto(); // Obtén la función signOut aquí*/

    return useQuery({
        queryKey: [QUERY_GET_MESSAGES, token, id],
        queryFn: async () => {
            const {data} = await fetchMessages(token, id);
            return {data};
        },
        enabled: !!token && !!id,
    })
}

//Fetch Messages Stats from Assistant
export const UseFetchMessagesStats = (token: string, id: string | undefined, from: string, to: string) => {

    /*const { signOut } = useLogto(); // Obtén la función signOut aquí*/

    return useQuery({
        queryKey: [QUERY_GET_MESSAGES_STATS, token, id, from, to],
        queryFn: async () => {
            const {data} = await fetchMessagesStats(token, id, from, to);
            return data;
        },
        staleTime: 1000,
        enabled: !!token && !!id && !!from && !!to
    })
}

//Fetch Conversation Data
export const UseFetchConversationsInfo = (token: string, id: string | undefined) => {

    /*    const { signOut } = useLogto(); // Obtén la función signOut aquí*/

    return useQuery({
        queryKey: [QUERY_GET_CONVERSATIONS_INFO, token, id],
        queryFn: async () => {
            const {data} = await fetchConversationsInfo(token, id);
            return data
        },
        staleTime: 100,
        enabled: !!token && !!id,
    })
}

// Check if Call is available
export const UseCheckCall = (token: string, id: string | undefined) => {

    /*const { signOut } = useLogto(); // Obtén la función signOut aquí*/

    return useQuery({
        queryKey: [QUERY_CHECK_CALL, id],  // Añadir `id` al queryKey para manejarlo mejor
        queryFn: async () => {
            try {
                const {data, status} = await checkCall(token, id);
                return {data, status};
            } catch (error) {
                throw new Error("Error fetching call request");
            }
        },
        enabled: false,
        retry: 2,  // Opcional, número de reintentos en caso de error
        staleTime: 0,  // Opcional, tiempo en milisegundos para que los datos sean considerados frescos
    });

};

// Request Call
export const UseRequestCall = (token: string, id: string | undefined) => {

    /*const { signOut } = useLogto(); // Obtén la función signOut aquí*/

    return useMutation({
        mutationKey: [QUERY_REQUEST_CALL],
        mutationFn: async (phoneNumber:PhoneObject) => {
            if (!token || !id) {
                throw new Error("Token o ID no proporcionados."); // Lanza un error si no hay token o ID
            }
            try {
                const {data} = await requestCall(token, id, phoneNumber); // Realiza la llamada
                return data; // Devuelve la respuesta
            } catch (error) {
                console.error("Error al hacer la llamada:", error); // Maneja el error aquí
                throw error; // Lanza el error para que el código superior lo maneje
            }
        },
        retry: 3,
    });
}

// Fetch Web Token
export const UseFetchWebToken = (token: string, id: string | undefined) => {

    const { signOut } = useLogto(); // Obtén la función signOut aquí

    return useQuery({
        queryKey: [QUERY_GET_WEB_TOKEN, token, id],
        queryFn: async () => {
            try {
                const {data} = await fetchRequest(token, `/api/assistants/${id}/webchat/token`, signOut);
                return data
            } catch (error) {
                throw new Error("Error fetching assistants");
            }
        },
        enabled: !!token && !!id,
        staleTime: 0
    });

}

// Send Chat Message
export const UseWebChat = (token: string) => {

    /*const { signOut } = useLogto(); // Obtén la función signOut aquí*/

    return useMutation({
        mutationKey: [QUERY_WEB_CHAT], // Usa un array vacío si no hay chat_id
        mutationFn: async (chatBody: ChatSent) => {
            const { data } = await postChatMessage(token, chatBody); // Enviar el cuerpo del mensaje
            return data; // Devolver los datos si la petición es exitosa
        },
        onError: (error: AxiosError) => {
            console.error("Error:", error.response?.data);
        }
    });
};
