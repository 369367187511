import React from "react";
import './CanaiaLateralMenuAssistant.scss';
import {CanaiaOptionMenuIcon} from "../../atoms/CanaiaOptionMenuIcon/CaniaOptionMenuIcon.tsx";
import IconMetrics from '../../../assets/images/option-metrics.svg';
import IconAssistant from '../../../assets/images/option-assistant.svg';
import IconPlayground from '../../../assets/images/option-playground.svg'
import IconChannels from '../../../assets/images/option-channels.svg';
import IconSettings from '../../../assets/images/option-settings.svg';
import {useTranslation} from "react-i18next";

// Definimos las opciones posibles para el menú lateral
export type MenuOption = "Metrics" |  "Assistant" | "Playground" | "Channels"  | "Settings";

// Array que contiene todas las opciones del menú lateral y su icono
/**/

// El prop 'onSelect' es una función que espera recibir una opción del tipo 'MenuOption' cuando se selecciona una opción del menú.
interface  Props {
    localActiveOption?:MenuOption;
    handleClick: (option: MenuOption) => void;
}
export const CanaiaLateralMenuAssistant: React.FC<Props> = ({localActiveOption,handleClick}) => {

    const { t } = useTranslation();

    const optionsMenu = [
        { icon: IconMetrics, text: 'Metrics', display: t('metrics_pages.metrics') },
        { icon: IconAssistant, text: 'Assistant', display: t('metrics_pages.assistant') },
        { icon: IconPlayground, text: 'Playground', display: t('metrics_pages.playground') },
        { icon: IconChannels, text: 'Channels', display:t('metrics_pages.channels') },
        { icon: IconSettings, text: 'Settings', display: t('metrics_pages.settings') },
    ];

    //

    return (
        <div className="canaia-lateral-menu-assistant">
            {optionsMenu.map((option, index) => (
                <CanaiaOptionMenuIcon
                    text={option.display}
                    key={index}
                    image={option.icon}
                    onClick={()=>handleClick(option.text as MenuOption)}
                    isSelected={ localActiveOption === option.text }
                />
            ))}
        </div>
    )
};
