import React from "react";
import './CanaiaTextWithIconColor.scss';

interface CanaiaTextWithIconColorProps {
    icon: string;
    text: string;
    color: string;
    alt?: string;
}

export const CanaiaTextWithIconColor: React.FC<CanaiaTextWithIconColorProps> = ({icon, text, color, alt}) => {
    return (
        <div className={`canaia-button-with-icon-wrapper ${color ? color:''}`}>
            <div className="icon-wrapper">
                <img src={icon} alt={alt} />
            </div>
            <div className="text-wrapper">
                {text}
            </div>
        </div>
    )
}