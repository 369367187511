import DataTable, { TableColumn } from "react-data-table-component";
import { useEffect, useState } from "react";
import './CanaiaTable.scss';
import { DocumentBlank, TrashCan } from "@carbon/icons-react";
import { UseDeleteConversation } from "../../../api/ApiCalls.tsx";
import { authStore } from "../../../store/authStore.tsx";
import { Modal } from "@carbon/react";
import { ConversationsModal } from "../../components/ConversationsModal/ConversationsModal.tsx";
import {CanaiaToastNotification} from "../CanaiaToastNotification/CanaiaToastNotification.tsx";
import {useTranslation} from "react-i18next";

interface ConversationType {
    id: string;
    bot?: string;
    channel: string;
    created_at: string;
}

interface CanaiaTableProps {
    dataRow: { id: string; created_at: string; channel: string }[];
    onDelete: (id: string, status: string) => void;
}

export const CanaiaTable = ({ dataRow, onDelete }: CanaiaTableProps) => {
    const [rowRecords, setRowRecords] = useState<ConversationType[]>([]);
    const { accessToken } = authStore();
    const [open, setOpen] = useState(false);
    const [openMessage, setOpenMessage] = useState(false);
    const [conversationId, setConversationId] = useState('');
    const deleteMutation = UseDeleteConversation(accessToken);
    const {t} = useTranslation();

    //funciones para toast
    const [showToast, setShowToast] =  useState(false);
    const [message, setMessage] = useState('');  // Estado para el mensaje
    const [messageType, setMessageType] = useState<'error' | 'info' | 'info-square' | 'success' | 'warning' | 'warning-alt'>('success');
    /*const status = 'success';*/

    useEffect(() => {
        if (dataRow) {
            setRowRecords(dataRow);
        }
    }, [dataRow]);

    const prepareActionDelete = (id: string) => {
        setOpen(true);
        setConversationId(id.toString());
    }

    const deleteConversation = () => {
        if (conversationId !== '') {
            setRowRecords(prevRecords => prevRecords.filter(record => record.id !== conversationId)); // Optimistic update

            deleteMutation.mutate(conversationId, {
                onSuccess: () => {
                    onDelete(conversationId, 'success');
                    setOpen(false);
                    setMessage(t('toasts.success_conversations_deleted'));
                    setMessageType('success');
                    setShowToast(true);
                },
                onError: (error) => {
                    onDelete(conversationId, 'error');
                    console.error(error);
                    setMessage(t('toasts.error_conversations_deleted'));
                    setMessageType('error');
                    setShowToast(true);
                },
            });
            // Si se elimina la única conversación, mostrar el toast aquí
            if (rowRecords.length === 1) {
                setMessage(t('toasts.error_conversations_deleted'));
                setMessageType('success');
                setShowToast(true);
            }
        }
    }

    const openConversation = (id: string) => {
        setConversationId(id.toString());
        setOpenMessage(true);
    }

    const closeConversation = () => {
        setOpenMessage(false);
    }

    const formatTimeStamp= (created_at: string, id:string) => {
        // Expresión regular para validar el formato de fecha y hora: YYYY-MM-DDTHH:mm:ss.ssssss
        const dateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{6}$/;
        // Verifica si 'created_at' coincide con el formato de fecha y hora
        const isDateFormat = dateRegex.test(created_at);
        //Formatear el valor de la fecha
        const displayValue = isDateFormat ? `${created_at.slice(0, 10)} @ ${created_at.slice(11, 16)}` : created_at.slice(0, -7);

        return (
            <div className={'conversation-button'} onClick={() => openConversation(id)}>
                <DocumentBlank size={21} aria-hidden="true"/> {displayValue}
            </div>
        );
    };

    const columns: TableColumn<ConversationType>[] = [
        {
            name: <div className="centered-header">{t('metrics.conversation')}</div>,
            cell: dataRow => formatTimeStamp(dataRow.created_at, dataRow.id),
            style: {
                fontFamily: 'Sansation-Bold',
            },
        },
        {
            name: t('metrics_pages.channel'),
            selector: row => row.channel, // Asegúrate de que esto sea correcto
            sortable: true,
            style: {
                fontFamily: 'Sansation-Bold',
                textTransform: 'capitalize',
                textAlign: 'center',
            },
            width: '200px'
        },
        /*{
            name: <div className="centered-header">Solved</div>,
            cell: () => (
                <div className={`${status === 'success' ? 'text-success' : 'text-pending'}`} style={{ textAlign: 'center', width:'100%'}}>
                    Completed
                </div>
            ), // Consider renaming to getSolvedStatus
            sortable: true,
            width: '150px'
        },*/
        {
            name: <div className="centered-header">{t('metrics.actions')}</div>,
            cell: dataRow => (
                <div className={'action-delete'} onClick={() => prepareActionDelete(dataRow.id)} aria-label={`Delete conversation ${dataRow.id}`} style={{alignItems:'center', color:'#94A3B8',display:'flex',justifyContent: 'center', width:'100%'}}>
                    <TrashCan size={21} className={'icon-danger-color'} aria-hidden="true"/>
                </div>
            ),
            style: {
                cursor: 'pointer'
            },
            width: '200px'
        }
    ];

    return (
        <>
            <DataTable
                className={'conversation-table'}
                columns={columns}
                pagination={true}
                data={rowRecords}>
            </DataTable>
            <ConversationsModal conversationId={conversationId} onClick={closeConversation} open={openMessage} />
            <Modal
                open={open}
                onRequestClose={() => setOpen(false)}
                onRequestSubmit={deleteConversation}
                danger
                modalHeading="¿Estás seguro de que deseas eliminar esta conversación?"
                primaryButtonText="Eliminar Conversación"
                secondaryButtonText="Cancelar"
            />
            {showToast && (
                <CanaiaToastNotification
                    message={message}
                    type={messageType}
                    onClose={() => setShowToast(false)}
                />
            )}
        </>
    );
}
