import {useCallback, useEffect, useState} from "react";
import {UseFetchOneAssistant, UseFetchWebToken} from "../../../api/ApiCalls.tsx";
import {authStore} from "../../../store/authStore.tsx";
import {Button, InlineLoading} from "@carbon/react";
import {useNavigate} from "react-router-dom";
import './MetricsHeader.scss';
import useGetPageId from "../../../hooks/useGetPageId.tsx";
import IconArrow from '../../../assets/images/arrow.svg';
import {CopyLink} from "@carbon/icons-react";
import {CanaiaToastNotification} from "../../atoms/CanaiaToastNotification/CanaiaToastNotification.tsx";
import {useTranslation} from "react-i18next";

export const MetricsHeader = () => {
    const {accessToken} = authStore(); // Obtiene el token de acceso
    const idPage = useGetPageId(); // Obtiene el ID de la página
    const {data, isPending, isError} = UseFetchOneAssistant(accessToken, idPage); // Llama a la API para obtener datos
    const navigate = useNavigate();
    const webToken = UseFetchWebToken(accessToken, idPage); // Obtiene el token web
    const {t} = useTranslation();

    //funciones para toast
    const [showToast, setShowToast] = useState(false);
    const [message, setMessage] = useState('');  // Estado para el mensaje
    const [messageType, setMessageType] = useState<'error' | 'info' | 'info-square' | 'success' | 'warning' | 'warning-alt'>('success');

    useEffect(() => {
        window.scrollTo(0, 0); // Desplaza la ventana hacia arriba al cargar el componente
    }, []);

    const handleNavigate = useCallback(() => {
        const shareableLink = `${window.location.origin}/my-assistant?tokenId=${webToken?.data?.token}`;

        if (webToken.data) {
            navigator.clipboard.writeText(shareableLink)
                .then(() => {
                    setMessage(t('toasts.info_link_copied'));  // Mensaje de éxito
                    setMessageType('info');
                    setShowToast(true);
                })
                .catch(err => {
                    console.error('Error al copiar el enlace:', err);
                    setMessage(t('toasts.error_link_copied'));  // Mensaje de error
                    setMessageType('error');
                    setShowToast(true);

                });
        } else {
            setMessage(t('toasts.error_link_copied'));  // Mensaje de error
            setMessageType('error');
            setShowToast(true);
        }


    }, [t, webToken.data]);

    if (isPending || !data) {
        return <InlineLoading/>;
    }

    // Manejo de errores: redirige a la página de asistentes después de 2 segundos si hay un error
    if (isError) {
        setTimeout(() => {
            navigate("/assistants")
        }, 2000);
        return <p>{t('metrics_pages.there_was_error')}</p>
    }
    const handleGOBack = () => {
        navigate("/")
    }

    return (
        <>
            {data.type && data.name && ( // Asegúrate de que `data.type` y `data.name` existen
                <div className="wrapper-header-assistant">
                    <div className="header-image">
                        <div className="return" onClick={handleGOBack}>
                            <img src={IconArrow} alt="Imagen de Flecha"/>
                        </div>
                    </div>
                    <div className="header-text">
                        <div className="metric-type">{data.type}</div>
                        <div className="metric-name">{data.name}</div>
                        <Button className={'share-btn'} kind={"ghost"} onClick={handleNavigate}>
                            {t('metrics_pages.share_my_assistant')}
                            <CopyLink size={24} aria-hidden="true"/>
                        </Button>

                        {showToast && (
                            <CanaiaToastNotification
                                message={message}
                                type={messageType}
                                onClose={() => setShowToast(false)}
                            />
                        )}

                    </div>
                </div>
            )}
        </>
    );
};
