import React from 'react';
import './CanaiaLineChart.scss';
import { LineChart,ScaleTypes,TickRotations} from '@carbon/charts-react';
import '@carbon/charts-react/styles.css';
import {CanaiaTitleSimple} from "../CanaiaTitleSimple/CanaiaTitleSimple.tsx";
import {useTranslation} from "react-i18next";

interface CanaiaLinearChartProps {
    data: { date: string; value: number }[];
}

export const CanaiaLinearChart: React.FC<CanaiaLinearChartProps> = ({data}) => {

    const {t} = useTranslation()
    
    // Agregar etiqueta al al group para presentación en gráfica
    const formattedData = data.map(point => ({
        date: point.date,
        value: point.value,
        group: t('metrics.all'), // Etiqueta del grupo
    }));

    const options= {
        height: '400px',
        axes: {
            left: {
                title: t('metrics.messages'),
                mapsTo: 'value',
            },
            bottom: {
                title: t('metrics.date'),
                scaleType: ScaleTypes.TIME, // Asegúrate de que esto es 'time' o un valor válido
                mapsTo: 'date',
                ticks: {
                    rotation: TickRotations.ALWAYS,
                },
            },
        },
        series: {
            grouping: true,
        }
    };

    return (
        <div className="canaia-linear-chart-wrapper" >
            <CanaiaTitleSimple title={t('metrics.conversations_by_day')}/>
            <LineChart data={formattedData} options={options} />
        </div>
    );
};
