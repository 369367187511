import React from "react";
import './CanaiaDataPicker.scss';
import { DatePicker, DatePickerInput } from "@carbon/react";

interface CanaiaDataPickerProps {
    onDateChange: (date: string, pickerId: string) => void;
    text?: string;
    pickerId: string;
    date: string;
}
export const CanaiaDataPicker: React.FC<CanaiaDataPickerProps> = ({onDateChange, text, pickerId, date}) => {
    const handleDateChange = (dateArray: Date[]) => {
        // Verifica si hay al menos una fecha seleccionada
        if (dateArray.length > 0) {
            // Toma la primera fecha del array de fechas seleccionadas
            const selectedDate = dateArray[0];
            // Formatea la fecha seleccionada en el formato 'YYYY-MM-DD'
            const formattedDate = `${selectedDate.getFullYear()}-${(selectedDate.getMonth() + 1).toString().padStart(2, '0')}-${selectedDate.getDate().toString().padStart(2, '0')}`;
            onDateChange(formattedDate, pickerId);
        }
    };

    return (
        <div className="canaia-datapicker-wrapper">
            <DatePicker datePickerType="single" dateFormat="Y/m/d" value={date} onChange={handleDateChange}>
                <DatePickerInput
                    placeholder="YYYY-MM-DD"
                    labelText={text}
                    id={`date-picker-${pickerId}`}
                    size="md"
                />
            </DatePicker>
        </div>
    );
};
