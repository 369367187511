import React from 'react';
import './CanaiaSubtitleSimple.scss';

interface CanaiaSubtitleSimpleProps {
    title: string;
}

export const CanaiaSubtitleSimple: React.FC<CanaiaSubtitleSimpleProps> = ({
    title,
})=>{
    return (
        <div className="canaia-subtitle-wrapper">
            <span>{title}</span>
        </div>
    );
};