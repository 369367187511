import React from "react";
import {Add, DocumentBlank, Catalog,Restaurant, Hotel} from "@carbon/icons-react";
import './AssistantCard.scss'

interface AssistantCardProps {
    description: string,
    iconName: keyof typeof assistantTypes,
    onClick: () => void
    type: string,
}

const assistantTypes = {
    'Add': Add,
    'Catalog': Catalog,
    'DocumentBlank': DocumentBlank,
    'Restaurant': Restaurant,
    'Hotel': Hotel,
};
export const AssistantCard: React.FC<AssistantCardProps> = ({
    description,
    iconName,
    type,
    onClick
}) => {
    const IconComponent = assistantTypes[iconName];
    return (
        <div role={"button"} className={"card-button"} onClick={onClick}>
            <div>
                <div className="icon icon__card">
                    {IconComponent && <IconComponent className={"icon-primary-color"} size={48} />}
                </div>
                <h2>{type}</h2>
                <p>{description}</p>
                <div className={"icon icon__add"}>
                    <Add className={"icon-primary-color"} size={48}></Add>
                </div>
            </div>
        </div>
    )
}