import React, {useState} from "react";
import './ChannelsViewSection.scss';
import {CanaiaInternalChannelsMenu} from "../CanaiaInternalChannelsMenu/CanaiaInternalChannelsMenu.tsx";
import {ChannelOptions} from "../CanaiaInternalChannelsMenu/CanaiaInternalChannelsMenu.tsx";
import {CanaiaInnerChannelPhone} from "../CanaiaInnerChannelPhone/CanaiaInnerChannelPhone.tsx";
import {CanaiaInnerChannelTelegram} from "../CanaiaInnerChannelTelegram/CanaiaInnerChannelTelegram.tsx";
import {CanaiaInnerChannelWhatsApp} from "../CanaiaInnerChannelWhatsApp/CanaiaInnerChannelWhatsApp.tsx";
import {useGetAccessToken} from "../../../hooks/useGetAccessToken.tsx";
import useGetPageId from "../../../hooks/useGetPageId.tsx";
import {UseFetchChannels} from "../../../api/ApiCalls.tsx";
import {Loading} from "@carbon/react";

interface ChannelsViewSectionProps {}

export interface ChannelType {
    id: number;
    bot?: string;  // Opcional, ya que algunos tipos de canal no tendrán `bot`.
    type: string;
    phonenumber?: string // Opcional, ya que algunos tipos de canal no tendrán `numero`.
}

export const ChannelsViewSection: React.FC<ChannelsViewSectionProps> = () => {

    const accessToken = useGetAccessToken();
    const pageId = useGetPageId();

    const {data, isLoading, isError, refetch: dataRefetch} = UseFetchChannels(accessToken, pageId);

    const [selectedOption, setSelectedOption] = useState<ChannelOptions>("Phone");

    const handleRefetch =()=>{
        dataRefetch().then();
    }

    const handleChannelOption = (option: ChannelOptions) => {
        setSelectedOption(option);
    }

    // Datos pre-filtrados.
    const phoneChannels = data?.items?.filter((channel: ChannelType) => channel.type === 'phone') || [];
    const telegramChannels = data?.items?.filter((channel: ChannelType) => channel.type === 'telegram') || [];
    const whatsappChannels = data?.items?.filter((channel: ChannelType) => channel.type === 'whatsapp') || [];

    //const webChannels = data?.items?.filter((channel: ChannelType) => channel.type === 'web') || [];

    // Objeto que mapea las opciones del menú lateral a sus respectivos componentes de vista.
    const sections: Record<ChannelOptions, JSX.Element | null> = {
        'Phone': <CanaiaInnerChannelPhone channels={phoneChannels} handleDelete={handleRefetch} />,
        'Telegram': <CanaiaInnerChannelTelegram channels={telegramChannels} handleDelete={handleRefetch}/>,
        'WhatsApp': <CanaiaInnerChannelWhatsApp channels={whatsappChannels} handleDelete={handleRefetch}/>,
    };
    return (
        <div className="metrics-card">
            <div className="channels-view-section-wrapper">

                {isLoading && (
                    <Loading className="channels-loading" withOverlay={false}/>
                )}

                {isError && (
                    <div className="error-message">Error fetching channels</div>
                )}

                {/* Este contenedor solo se renderiza si no hay carga ni error */}
                {!isLoading && !isError && (
                    <div className="channels-view-section-inner-wrapper">
                        <CanaiaInternalChannelsMenu
                            handleClick={handleChannelOption}
                            localActiveOption={selectedOption}
                        />
                        <div className="channels-views-section-text-wrapper">
                            {sections[selectedOption]}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
