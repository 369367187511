import React from "react";
import './CaniaOptionMenuIcon.scss';

interface  CaniaOptionMenuIconProps {
    image: string;
    text: string;
    onClick?: () => void;
    isSelected?: boolean;
}

export const CanaiaOptionMenuIcon: React.FC<CaniaOptionMenuIconProps> = ({
    image,
    text,
    onClick,
    isSelected
}) => {
    return (
        <div className={`canaia-option-menu-icon-wrapper ${isSelected ? 'active' : ''}`} onClick={onClick}>
            <div className="icon-option-wrapper">
                <div className="icon-option">
                    <img src={image}/>
                </div>
            </div>
            <div className="text-option-wrapper">
                {text}
            </div>
        </div>
    )
}