import React, {useContext, useEffect, useState} from "react";
import {ActionableNotification, Column, Grid} from "@carbon/react";
import { AssistantHeader } from "../../../ui/modules/AssistantHeader/AssistantHeader";
import { CanaiaLateralMenuAssistant } from "../../../ui/components/CanaiaLateralMenuAssistant/CanaiaLateralMenuAssistant.tsx";
import { MetricsHeader } from "../../../ui/components/MetricsHeader/MetricsHeader.tsx";
import { MetricsViewSection } from "../../../ui/components/MetricsViewSection/MetricsViewSection.tsx";
import { AssistantViewSection } from "../../../ui/components/AssistantViewSection/AssistantViewSection.tsx";
import { PlaygroundViewSection } from "../../../ui/components/PlaygroundViewSection/PlaygroundViewSection.tsx";
import { ChannelsViewSection } from "../../../ui/components/ChannelsViewSection/ChannelsViewSection.tsx";
import { SettingsViewSection } from "../../../ui/components/SettingsViewSection/SettingsViewSection.tsx";
import { MenuOption } from "../../../ui/components/CanaiaLateralMenuAssistant/CanaiaLateralMenuAssistant.tsx";
import useGetPageId from "../../../hooks/useGetPageId.tsx";
import {useTranslation} from "react-i18next";
import {AuthContext} from "../../../context/AuthContext.tsx";
import {useNavigate} from "react-router-dom";
import {SubscriptionBanner} from "../../../ui/components/SubscriptionBanner/SubscriptionBanner.tsx";

interface Props {}

export const MetricsLayout: React.FC<Props> = () => {
    const pageId = useGetPageId();
    // Inicializa el estado desde localStorage
    const [selectedOption, setSelectedOption] = useState<MenuOption>("Metrics");
    const [showExitConfirm, setShowExitConfirm] = useState(false);
    const [nextOption, setNextOption] = useState<MenuOption | null>(null);
    const {subscriptionData} = useContext(AuthContext) || {}; // Asegura que `AuthContext` no sea null
    const navigate = useNavigate()
    const {t} = useTranslation();

    // Comprobación para mostrar el banner de suscripción:
    const showSubscriptionBanner = !subscriptionData?.subscription ||
        !subscriptionData.subscription.type || // Valida si el tipo de suscripción no está definido
        new Date() > new Date(subscriptionData.subscription.period_end); // Compara fechas de expiración


    const handleRenew = () => {
        navigate("/subscription");
    };

    useEffect(() => {
        // Busca si existe un valor almacenado en localStorage con la clave 'activeMenuOption'
        const storedOption = localStorage.getItem('activeMenuOption') as MenuOption;
        if (storedOption) {
            setSelectedOption(storedOption);
        }
    }, []);

    const handleMenuOption = (option: MenuOption) => {
        //guardar opción en localstorage
        /*localStorage.setItem('activeMenuOption', option);*/
        // Solo mostrar la confirmación si el usuario está en Playground
        if (selectedOption === "Playground" && option !== "Playground") {
            setShowExitConfirm(true);
            setNextOption(option);
        } else {
            setSelectedOption(option);
        }
    };

    const confirmExit = () => {
        setShowExitConfirm(false);
        if (nextOption) {
            setSelectedOption(nextOption);
            setNextOption(null); // Reiniciar el valor
        }
    };

    const cancelExit = () => {
        setShowExitConfirm(false);
        setNextOption(null); // Reiniciar el valor
    };

    const sections: Record<MenuOption, JSX.Element> = {
        Metrics: <MetricsViewSection />,
        Assistant: <AssistantViewSection />,
        Playground: <PlaygroundViewSection />,
        Channels: <ChannelsViewSection />,
        Settings: <SettingsViewSection />,
    };
    /*
    * Eventos
    * Integraciones
    * Versiones
    * */
    return (
        <>
            <style>
                {`
                .custom-overlay-alert {
                    background: rgba(0,0,0,0.4);
                    backdrop-filter: blur(5px);
                    height: 100%;
                    left:0;
                    position:fixed;
                    top:0;
                    width: 100%;
                    z-index: 999;
                }
                .cds--actionable-notification {
                    background:rgba(0, 0, 0, 0.7);
                    border-radius: .5rem;
                    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
                    left:50%;
                    max-width:35%;
                    opacity: 1;
                    padding: 2rem;
                    position: fixed;
                    transform: translate(-50%,-50%);
                    transition: opacity 0.3s ease;
                    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
                    top:50%;
                }
                .overlay-alert.show {
                  opacity: 1;
                }
                .cds--actionable-notification__title {
                    font-size: 2rem;
                }
                .cds--actionable-notification__subtitle {
                    font-size: 1.5rem;
                    margin-top:2rem;
                }
                .cds--actionable-notification__button-wrapper {
                    display: flex;
                    justify-content: right;
                    width: 100%;
                }
                `}
            </style>
            <AssistantHeader page={'blue-header'} onSelectSettings={() => setSelectedOption("Settings")}></AssistantHeader>
            {showSubscriptionBanner && (
                <SubscriptionBanner
                    onRenew={handleRenew}
                />
            )}
            <div className={`metrics-title ${showSubscriptionBanner ? 'with-banner' : ''}`}>
                <Grid className={'app-wrapper app__metrics-title'} fullWidth={true}>
                    <Column sm={4} md={8} lg={16} className="metrics-page__banner">
                        <MetricsHeader />
                    </Column>
                </Grid>
            </div>
            <main className={`content-options-assistant-wrapper app__metrics`}>
                <Grid className={'app__metrics-grid'} fullWidth={true}>
                    <Column sm={0} md={0} lg={3}>
                        <CanaiaLateralMenuAssistant handleClick={handleMenuOption} localActiveOption={selectedOption} />
                    </Column>
                    <Column sm={4} md={8} lg={13}>
                        {pageId && sections[selectedOption]}
                    </Column>
                </Grid>
            </main>
            {showExitConfirm && (
                <div className={`custom-overlay-alert ${showExitConfirm ? 'show' : ''}`}>
                    <ActionableNotification
                        title={t('toasts.warning_you_want_to_leave')}
                        kind={'warning-alt'}
                        subtitle={t('toasts.warning_if_you_leave')}
                        onClose={cancelExit}
                        onActionButtonClick={confirmExit}
                        actionButtonLabel={t('toasts.exit')}
                    />
                </div>
            )}
        </>
    );
};
