import axios, { AxiosInstance } from 'axios';

const createApiClient = (token: string, signOut?: (s: string) => Promise<void>): AxiosInstance => {
    const client = axios.create({
        baseURL: import.meta.env.VITE_API_ENDPOINT,
        timeout: 3000,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        },
    });

    // Agrega un interceptor de respuesta
    client.interceptors.response.use(
        (response) => {
            return response; // Si la respuesta es exitosa, simplemente devuelve la respuesta
        },
        async (error) => {

            // Si el error es un 401 (No autorizado), cierra sesión
            if (error.response && (error.response.status === 401)) {
                // Cierra la sesión del usuario
                if (signOut) {
                    await signOut(window.location.origin + "/");
                } // Cierra la sesión del usuario
            }

            // Manejo de otros errores
            return Promise.reject(error);
        }
    );

    return client;
};

export default createApiClient;
