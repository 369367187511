import React, {useEffect, useState} from 'react';
import './CanaiaInnerChannelPhone.scss';
import {ChannelType} from "../ChannelsViewSection/ChannelsViewSection.tsx";
import DataTable, {TableColumn} from "react-data-table-component";
import { Loading, Modal} from "@carbon/react";
import {CanaiaButton} from "../../atoms/CanaiaButton/CanaiaButton.tsx";
import {CanaiaFormErrorStatus} from "../../atoms/CanaiaFormErrorStatus/CanaiaFormErrorStatus.tsx";
import {ChannelTable} from "../ChannelTable/ChannelTable.tsx";
import {UseRemoveAssistantChannel} from "../../../api/ApiCalls.tsx";
import {useTranslation} from "react-i18next";
import {authStore} from "../../../store/authStore.tsx";
import { TrashCan } from "@carbon/icons-react";
import {CanaiaToastNotification} from "../../atoms/CanaiaToastNotification/CanaiaToastNotification.tsx";

interface BotInfo {
    id: number;
    type?: string;
    phonenumber?: string;
}

interface Props {
    channels: ChannelType[]
    handleDelete: () => void;
}

export const CanaiaInnerChannelPhone : React.FC<Props> = ({channels, handleDelete}) => {
    const { accessToken } = authStore();
    const [open, setOpen] = useState(false);
    const [pending, setPending] = useState(true);
    const [botInfo, setBotInfo] = useState<BotInfo[]>([]);
    const updateMutation = UseRemoveAssistantChannel(accessToken);
    const {t} = useTranslation();
    // Funciones para toast
    const [showToast, setShowToast] = useState(false);
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState<'error' | 'info' | 'info-square' | 'success' | 'warning' | 'warning-alt'>('success');

    const handleClose = () => {
        setOpen(false);
    }

    const handleRemoveChannel = (id: number) => {
        updateMutation.mutate(id.toString(), {
            onSuccess: () => {
                setMessage(t('toasts.success_channel_removed'));
                setMessageType('success');
                setShowToast(true);
                handleDelete();
            },
            onError: (error) => {
                console.error(error);
                setMessage(t('toasts.error_removing_channel'));
                setMessageType('error');
                setShowToast(true);
            },
        });
    }

    useEffect(() => {
        if (channels.length > 0) {
            const bots = channels
                .map(channel => ({
                    id: channel.id,
                    type: channel.type,
                    phonenumber: channel.phonenumber
                }))
                .filter(info => info.type !== undefined && info.phonenumber !== undefined) as BotInfo[];

            setBotInfo(bots);
        }
        setPending(false); // Cambia el estado de carga al final de la obtención de datos
    }, [channels]);

    // Efecto para actualizar la tabla cuando el modal se cierra
    useEffect(() => {
        if (!open) {
            const bots = channels
                .map(channel => ({
                    id: channel.id,
                    type: channel.type,
                    phonenumber: channel.phonenumber
                }))
                .filter(info => info.type !== undefined && info.phonenumber !== undefined) as BotInfo[];
            setBotInfo(bots);
        }

    }, [open, channels]); // Escucha cambios en `open` y `channels`

    const columns: TableColumn<BotInfo>[] = [
        {
            name: t('channels.phone_number'),
            selector: row => row.phonenumber || 'N/A',
            sortable: true,
            cell: (row) => {
                const phoneNumber = row.phonenumber;
                // Si el teléfono está presente, devuelve el enlace, si no, devuelve 'N/A'
                return phoneNumber ? (
                    <a href={`tel:${phoneNumber}`} target="_blank" rel="noopener noreferrer nofollow">{phoneNumber}</a>
                ) : (
                    'N/A'
                );
            },
        },
        {
            name: t('channels.actions'),
            cell: (dataRow) => {
                // Imprimir el contenido de dataRow en la consola
                return (
                    <div
                        className={'action-delete'}
                        aria-label={`Removed channel ${dataRow.id}`}
                        style={{
                            alignItems: 'center',
                            color: '#94A3B8',
                            cursor: 'pointer',
                            display: 'flex',
                            justifyContent: 'right',
                            width: '100%',
                        }}
                        onClick={() => handleRemoveChannel(dataRow.id)}
                    >
                        <TrashCan
                            size={21}
                            className={'icon-danger-color'}
                            aria-hidden="true"
                        />
                    </div>
                );
            },
            sortable: false, // Si no quieres que esta columna sea sortable
        }
    ];

    return pending ? (
        <Loading className={"bots-loading"} withOverlay={false}/>
    ): (
        <div className="canaia-inner-channel-phone-wrapper">
            {showToast && (
                <CanaiaToastNotification
                    message={message}
                    type={messageType}
                    onClose={() => setShowToast(false)}
                />
            )}
            <CanaiaButton kind={'secondary'} onClick={()=> setOpen(true)} >{t('buttons.request_phone_number')}</CanaiaButton>
            <Modal className={'modal-channel'} passiveModal open={open} onRequestClose={() => setOpen(false)}>
                <ChannelTable type={'phone'} onClose={ handleClose }/>
            </Modal>

            {botInfo.length === 0 ? (
                <CanaiaFormErrorStatus text={t('channels.no_bots_available')}/>
            ) : (
                <>
                    <div className="channel-inner-options-wrapper">
                        <DataTable
                            columns={columns}
                            data={botInfo}
                            pagination={false}
                        />
                    </div>
                </>
            )}
        </div>
    )
}