import useGetPageId from "../../../hooks/useGetPageId.tsx";
import { UseFetchConversations } from "../../../api/ApiCalls.tsx";
import { authStore } from "../../../store/authStore.tsx";
import { Loading } from "@carbon/react";
import { CanaiaTable } from "../../atoms/CanaiaTable/CanaiaTable.tsx";
import { useEffect, useState } from "react";
import {CanaiaFormErrorStatus} from "../../atoms/CanaiaFormErrorStatus/CanaiaFormErrorStatus.tsx";
import {CanaiaToastNotification} from "../../atoms/CanaiaToastNotification/CanaiaToastNotification.tsx";
import {useTranslation} from "react-i18next";

interface ConversationType {
    id: string;
    bot?: string;
    channel: string;
    created_at: string;
}

export const ConversationsTable = () => {
    const pageId = useGetPageId();
    const { accessToken } = authStore();
    const { data, isLoading, isError, refetch: dataRefetch } = UseFetchConversations(accessToken, pageId);
    const [conversations, setConversations] = useState<ConversationType[]>([]);
    const {t} = useTranslation();
    //funciones para toast
    const [showToast, setShowToast] =  useState(false);
    const [message, setMessage] = useState('');  // Estado para el mensaje
    const [messageType, setMessageType] = useState<'error' | 'info' | 'info-square' | 'success' | 'warning' | 'warning-alt'>('success');

    useEffect(() => {
        if (data && data.status === 200) {
            setConversations(data.data.items);
        }
    }, [data]);

    const handleDelete = (id: string, status: string) => {
        if (status === 'success') {
            setConversations(prevConversations =>
                prevConversations.filter(conversation => conversation.id.toString() !== id)
            );
            setMessage(t('success_conversations_deleted'));  // Mensaje de éxito
            setMessageType('success');
            setShowToast(true);
            dataRefetch().then(resp => resp);
        } else if (status === 'error') {
            setMessage(t('error_conversations_deleted'));  // Mensaje de error
            setMessageType('error');  // Tipo de mensaje
            setShowToast(true);
        }
    };

    if (isLoading || data === undefined) {
        return (
            <div className="metrics-card">
                <Loading className={'table-loading'} withOverlay={false} />
            </div>
        );
    }

    if (isError) {
        return (
            <div className="metrics-card">
                <CanaiaFormErrorStatus text={t('there_was_error')}/>
            </div>
        );
    }

    if (conversations.length === 0) {
        return (
            <div className="metrics-card">
                <CanaiaFormErrorStatus text={t('metrics.no_conversations_to_display')}/>
            </div>
        );
    }

    return (
        <div className="metrics-card">
            <h4>{t('metrics.conversations')}</h4>
            {showToast && (
                <CanaiaToastNotification
                    message={message}
                    type={messageType}
                    onClose={()=>setShowToast(false)}
                />
            )}
            <CanaiaTable dataRow={conversations} onDelete={handleDelete} />
        </div>
    );
};

export default ConversationsTable;
