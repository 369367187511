import {
    UseFetchAvailableChannels,
    UsePutAssistantChannel,
    UseFetchChannels
} from "../../../api/ApiCalls.tsx";
import {useGetAccessToken} from "../../../hooks/useGetAccessToken.tsx";
import {Loading} from "@carbon/react";
import {CanaiaFormErrorStatus} from "../../atoms/CanaiaFormErrorStatus/CanaiaFormErrorStatus.tsx";
import {ChannelType} from "../ChannelsViewSection/ChannelsViewSection.tsx";
import DataTable, {TableColumn} from "react-data-table-component";
import {CanaiaButton} from "../../atoms/CanaiaButton/CanaiaButton.tsx";
import './ChannelTable.scss';
import useGetPageId from "../../../hooks/useGetPageId.tsx";
import {useState} from "react";
import {CanaiaToastNotification} from "../../atoms/CanaiaToastNotification/CanaiaToastNotification.tsx";
import {useTranslation} from "react-i18next";

interface Props {
    type: string;
    onClose: () => void; // Añadir prop para cerrar el modal
}

const dataTest = {
    "items": [
        {
            "id": 0,
            "type": "telegram",
            "bot": "assist_staging_civir_bot"
        },
        {
            "id": 1,
            "type": "phone",
            "phonenumber": "string"
        },
        {
            "id": 2,
            "type": "whatsapp",
            "phonenumber": "+13024501499"
        },
        {
            "id": 3,
            "type": "teams",
            "bot": "string"
        },
        {
            "id": 4,
            "type": "whatsapp",
            "phonenumber": "+13024505599"
        },
        {
            "id": 5,
            "type": "whatsapp",
            "phonenumber": "+13024501490"
        },
        {
            "id": 6,
            "type": "telegram",
            "bot": "assist_staging_civir_2_bot"
        },
        {
            "id": 7,
            "type": "whatsapp",
            "phonenumber": "+13024501410"
        },
        {
            "id": 8,
            "type": "telegram",
            "bot": "assist_staging_civir_3_bot"
        },
    ],
    "total": 0,
    "page": 1,
    "size": 1,
    "pages": 0
}

export const ChannelTable = ({type, onClose} : Props) => {
    const accessToken = useGetAccessToken();
    const assistantId = useGetPageId();
    const {t} = useTranslation();
    //const queryClient = useQueryClient();
    const queryClientChannel = UseFetchChannels(accessToken,assistantId);

    //funciones para toast
    const [showToast, setShowToast] =  useState(false);
    const [message, setMessage] = useState('');  // Estado para el mensaje
    const [messageType, setMessageType] = useState<'error' | 'info' | 'info-square' | 'success' | 'warning' | 'warning-alt'>('success');

    const useTestData = false;
    // Condición para usar los datos de prueba o la llamada a la API
    const {data, isLoading, isError} = useTestData
        ? {data: dataTest, isLoading: false, isError: false}
        : UseFetchAvailableChannels(accessToken, type);
    const mutation = UsePutAssistantChannel(accessToken, assistantId);

    const [addedChannels, setAddedChannels] = useState<Set<string>>(new Set()); // Estado para los canales agregados

    // Mostrar el estado de carga
    if (isLoading) {
        return <Loading withOverlay={false}></Loading>;
    }

    // Manejo de errores
    if (isError) {
        return <CanaiaFormErrorStatus text={t('channels.error_fetching_channels')}></CanaiaFormErrorStatus>
    }

    // Si no hay datos disponibles o no hay canales, mostrar un mensaje
    if (!data || data.items.length === 0) {
        return <CanaiaFormErrorStatus text={t('channels.no_channels_available')}></CanaiaFormErrorStatus>
    }

    // Filtrar los canales por el tipo especificado
    const filteredChannels = data.items.filter((channel: ChannelType) => channel.type === type);

    // Si no hay canales después del filtrado, mostrar un mensaje
    if (filteredChannels.length === 0) {
        return <CanaiaFormErrorStatus text={`No channels of type "${type}" available.`}/>;
    }

    //funcion para transformar el nombre del asistente
    const formatNameAssistant = (text:string) =>{
        return text.replace(/_/g, ' ') // Reemplaza guiones bajos con espacios
            .split(' ')// Divide la cadena en palabras
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))// Capitaliza cada palabra
            .join(' ');
    }

    const formattedCell = (dataType:ChannelType) => {

        let row = dataType.phonenumber
        if(dataType.type === "telegram") {
            row = dataType.bot
        }
        const formateName = formatNameAssistant(row || t('channels.value_not_available')); // Valor por defecto
        return <p>{formateName}</p>
    }

    // Usa la mutación para agregar el canal al asistente
    const handleAddChannel = (channelId: string) => {

        mutation.mutate(channelId, {
            onSuccess: () => {
                setMessage(t('toasts.success_channel_added'));
                setMessageType('success');
                setShowToast(true);
                setAddedChannels(prev => new Set(prev).add(channelId)); // Agregar canal a la lista de agregados
                // Invalidar la consulta para que se vuelva a obtener la lista de canales
                queryClientChannel.refetch().then(resp=>resp);
                setTimeout(() => {
                    setMessage('');
                    onClose();
                }, 3000);  // Limpiar el mensaje después de 3 segundos
            },
            onError: (error) => {
                setMessage('toasts.error_while_adding_channel');
                setMessageType('error');
                setShowToast(true);
                console.log(error);
                setTimeout(() => {
                    setMessage('')
                }, 3000);  // Limpiar el mensaje después de 3 segundos
            }
        })
    };

    const columns: TableColumn<ChannelType>[] = [
        {
            cell: dataRow =>  formattedCell(dataRow),
        },
        {
            name: "",
            cell: dataRow => (
                <CanaiaButton
                    onClick={() => handleAddChannel(dataRow.id.toString())}
                    kind="primary"
                    disabled={mutation.isPending || addedChannels.has(dataRow.id.toString())} // Desactiva el botón si se está añadiendo un canal o si ya fue agregado
                >
                    {mutation.isPending ? "Adding..." : "Add Channel"}
                </CanaiaButton>
            ),
            style: {
                cursor: 'pointer',
            },
            width: '250px'
        }
    ]

    return (
        <div className="channel-table">
            <DataTable
                pagination={false}
                title="Channels available"
                className={'channel-table'}
                columns={columns}
                data={filteredChannels}>
            </DataTable>
            {showToast && (
                <CanaiaToastNotification
                    message={message}
                    type={messageType}
                    onClose={() => setShowToast(false)}
                />
            )}
        </div>
    );
};
