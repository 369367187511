import { useState, useEffect } from 'react';

export const useGenerateUUID = () => {
    const [uuid, setUUID] = useState<string>('');

    useEffect(() => {
        const newUUID = crypto.randomUUID(); // Generar un nuevo UUID
        setUUID(newUUID); // Guardarlo en el estado
    }, []);

    return uuid;
};
