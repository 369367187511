import React from "react";
import './CanaiaPogressBar.scss';
import {ProgressBar} from "@carbon/react";

interface CanaiaPogressBarProps {
    className?: string;
    helperText?: string;
    hideLabel?: boolean;
    label: string;
    maxValue: number;
    size?: 'small' | 'big';
    status?: 'active'| 'finished'| 'error';
    type?: 'default' | 'inline' | 'indented';
    value: number;
}

export const CanaiaPogressBar: React.FC<CanaiaPogressBarProps> =({className,helperText,hideLabel,label,maxValue,size,status,type,value}) =>{
    return (
        <div className='canaia-pogress-bar'>
            <ProgressBar className={className} helperText={helperText} hideLabel={hideLabel} label={label} max={maxValue} size={size} status={status} type={type} value={value}/>
        </div>
    );
}