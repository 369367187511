import React from "react";
import './CanaiaTitleWithImage.scss'

interface CanaiaTitleWithImageProps {
    title1?: string,
    title2?: string,
    image: string,
    alt?: string,
}

export const CanaiaTitleWithImage : React.FC<CanaiaTitleWithImageProps> = ({
    title1,
    title2,
    image,
    alt
})=>{
    return (
        <div className="canaia-title-with-image-wrapper">
            <div className="canaia-title-image-wrapper">
                <img src={image} alt={alt}/>
            </div>
            <div className="canaia-title1">
                {title1}
            </div>
            <div className="canaia-title2">
                {title2}
            </div>
        </div>
    )
}