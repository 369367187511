import React, {useEffect, useState} from 'react';
import { CanaiaButton } from "../../atoms/CanaiaButton/CanaiaButton.tsx";
import { CanaiaInput } from "../../atoms/CanaiaInput/CanaiaInput.tsx";
import './PlayGround.scss';
import { useGetAccessToken } from "../../../hooks/useGetAccessToken.tsx";
import useGetPageId from "../../../hooks/useGetPageId.tsx";
import {UseCheckCall, UseFetchChannels, UseRequestCall} from "../../../api/ApiCalls.tsx";
import {InlineLoading} from "@carbon/react";
import {CanaiaToastNotification} from "../../atoms/CanaiaToastNotification/CanaiaToastNotification.tsx";
import {useTranslation} from "react-i18next";

interface BotInfo {
    type?: string;
    phonenumber?: string;
}

export const PlayGroundPhone = () => {
    const accessToken = useGetAccessToken();
    const pageId = useGetPageId();

    const [showInput, setShowInput] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [foundPhone, setFoundPhone] = useState<BotInfo | null>(null);  // Estado para guardar el elemento encontrado
    const [disabledStatus, setDisabledStatus] = useState(false);  // Estado para guardar el elemento encontrado
    const { refetch, isLoading } = UseCheckCall(accessToken, pageId);
    const { mutate: requestCall, isPending: isCalling } = UseRequestCall(accessToken, pageId);
    const {data, isLoading: channelLoading} = UseFetchChannels(accessToken, pageId);
    const {t} = useTranslation();

    //funciones para toast
    const [showToast, setShowToast] =  useState(false);
    const [message, setMessage] = useState('');  // Estado para el mensaje
    const [messageType, setMessageType] = useState<'error' | 'info' | 'info-square' | 'success' | 'warning' | 'warning-alt'>('success');

    useEffect(() => {
        if (data && data.items) {
            const foundItem = data.items.find((item:BotInfo) => item.type === 'phone');
            if (foundItem) {
                setFoundPhone(foundItem);
            } else {
                setFoundPhone(null);
            }
        }
    }, [data]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value.replace(/\s+/g, ''); // Quitar espacios
        setPhoneNumber(inputValue);
    };

    // Función para validar el formato E.164
    const isValidPhoneNumber = (number: string) => {
        const phoneRegex = /^\+[1-9]\d{1,14}$/;
        return phoneRegex.test(number);
    };

    // Validar y corregir el número si no tiene el símbolo '+'
    const formatPhoneNumber = (number: string) => {
        return number.startsWith('+') ? number : `+${number}`;
    };

    const handleButtonClick = () => {

        if(!foundPhone) {
            setDisabledStatus(true);
            setMessage(t('toasts.warning_no_assistant_number'));
            setMessageType('warning');
            setShowToast(true);
        }

        if (!showInput) {
            refetch()
                .then(resp => {
                    if (resp.isError) {
                        setMessage(t('toasts.error_making_call'));
                        setShowInput(false);
                    } else {
                        setShowInput(true); // Mostrar input si la llamada es exitosa
                        setMessage('');
                    }
                })
                .catch(error => {
                    console.error(error);
                    setMessage(t('toasts.error_unavailable_call'));
                    setShowInput(false);
                });
        } else {
            const formattedPhoneNumber = formatPhoneNumber(phoneNumber);
            // Validar el número antes de hacer la solicitud
            if (isValidPhoneNumber(formattedPhoneNumber)) {

                setMessage(t('toasts.info_requesting_please_wait'));
                setMessageType('info');
                setShowToast(true);

                requestCall({ 'phonenumber': formattedPhoneNumber }, {
                    onSuccess: () => {
                        setMessage(t('toasts.success_request_call'));
                        setMessageType('success');
                        setShowToast(true);
                    },
                    onError: () => {
                        setMessage(t('toasts.error_try_again'));
                        setMessageType('error');
                        setShowToast(true);
                    }
                });
            } else {
                setMessage(t('toasts.warning_only_numbers_allowed'));
                setMessageType('warning');
                setShowToast(true);
            }
        }
    };

    if (channelLoading || data === undefined) {
        return (
            <div className="d-flex playground-phone">
                <InlineLoading />
            </div>
        );
    }

    return (
        <>
            <div className="d-flex playground-phone">
                <p>{t('playground.if_you_need_a_call')}</p>
                {showInput && (
                    <div className="phone-input">
                        <CanaiaInput
                            id="phoneNumber"
                            label={""}
                            placeholder="+123456789012"
                            value={phoneNumber}
                            onChange={handleInputChange}
                        />
                    </div>
                )}
                <CanaiaButton
                    kind="primary"
                    width="160px"
                    onClick={handleButtonClick}
                    disabled={isLoading || isCalling || disabledStatus}  // Deshabilitar el botón si está cargando
                >
                    {isLoading || isCalling ? t('buttons.loading') : showInput ? t('buttons.submit_number') : t('buttons.request_call')}
                </CanaiaButton>
            </div>
            {showToast && (
                <CanaiaToastNotification
                    message={message}
                    type={messageType}
                    onClose={()=>setShowToast(false)}
                />
            )}
        </>
    );
};