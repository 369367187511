import logo from '../../../assets/images/canaia_logo_og.svg';
import white_logo from '../../../assets/images/canaia_logo_white.svg';
import './CanaiaLogo.scss';
import React from "react";

interface Props {
    className?: string
    responsiveImg?: boolean
    width?: string;
    type?: string
}

export const CanaiaLogo: React.FC<Props> = ({responsiveImg = true, width, type = 'logo-color', className}) => {

    let typeLogo = logo;

    if (type === 'logo-white') {
        typeLogo = white_logo
    }


    return <img className={className} style={{width: width, maxWidth: responsiveImg ? '' : 'none'}} src={typeLogo} alt={'CanaiaLogo'}/>;
}

export default CanaiaLogo
