import { InlineLoading } from "@carbon/react";
import { Navigate } from "react-router-dom";
import { useLogto } from "@logto/react";
import { useEffect } from "react";

export const Home = () => {
    const { isAuthenticated, isLoading, signIn } = useLogto();

    useEffect(() => {
        const handleSignIn = async () => {
            if (!isAuthenticated && !isLoading) {
                await signIn(window.location.origin + '/sign-in-callback');
            }
        };

        handleSignIn().then(resp => resp);
    }, [isAuthenticated, isLoading, signIn]);

    if (isLoading) {
        return <InlineLoading status="active" iconDescription="Loading" description="Redirecting..." />;
    }

    // Redirección solo si está autenticado
    if (isAuthenticated) {
        return <Navigate to={'/assistants'} replace />;
    }

    return null; // O puedes retornar un mensaje o un componente para informar al usuario
};

export default Home;
