import React from 'react';
import { Form } from "@carbon/react";
import './CanaiaFormEmpty.scss'

interface CanaiaFormEmptyProps {
    children: React.ReactNode;
}
export const CanaiaFormEmpty: React.FC<CanaiaFormEmptyProps> = ({children})=>{
    return (
        <Form className="canaia-form-empty">
            {children}
        </Form>
    );
};