import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import en from './locales/en.json';
import es from './locales/es.json';

// Definir un tipo para el mapeo de idiomas
type LanguageMap = {
    [key: string]: string;
};

// Mapa de idiomas
const languageMap: LanguageMap = {
    'es': 'es',          // Español
    'es-419': 'es',      // Español (Latinoamérica)
    'ca': 'es',          // Catalán
    'ca-ES': 'es',       // Catalán (España)
    'eu': 'es',          // Euskera
    'eu-ES': 'es',       // Euskera (España)
    'gl': 'es',          // Gallego
    'gl-ES': 'es',       // Gallego (España)
    'en': 'en',          // Inglés
    'en-US': 'en',       // Inglés (Estados Unidos)
    'en-GB': 'en',       // Inglés (Reino Unido)
};

export const initializeI18n = (language: string) => {

    const mappedLanguage = languageMap[language];

    i18n.use(initReactI18next).init({
        resources: {
            en: {translation: en},
            es: {translation: es},
        },
        lng: mappedLanguage,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // protección contra XSS
        },
    }).then(resp => resp);
}