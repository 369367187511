import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import {LogtoProvider, LogtoConfig, UserScope} from '@logto/react'
import {BrowserRouter} from "react-router-dom";
import './index.css'
import './theme/All.scss'
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";

const logtoConfig: LogtoConfig = {
    endpoint: import.meta.env.VITE_LOGTO_ENDPOINT,
    appId: import.meta.env.VITE_LOGTO_APP_ID,
    scopes: [
        "all", "admin",
        UserScope.Email,
        UserScope.CustomData,
        UserScope.Profile,
    ],
    resources: [
        import.meta.env.VITE_LOGTO_API_ID
    ]
}

const queryClient = new QueryClient({
    defaultOptions: { queries: { staleTime: 10000 }}
})

declare global {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    namespace JSX {
        interface IntrinsicElements {
            'potio-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> & {
                customer?: string;
                action?: string;
                'data-key'?: string;
                'customer-email'?: string;
            }
        }
    }
}


ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <LogtoProvider config={logtoConfig}>
                <BrowserRouter>
                    <App/>
                </BrowserRouter>
            </LogtoProvider>
        </QueryClientProvider>
    </React.StrictMode>,
)
