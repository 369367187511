import React from 'react';
import { TextInput } from "@carbon/react";
import './CanaiaInput.scss'

interface CanaiaInputProps {
    label: string;
    id: string;
    type?: string;
    required?: boolean;
    placeholder?: string;
    disabled?: boolean;
    width?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    value?: string;
}

export const CanaiaInput: React.FC<CanaiaInputProps> = ({
    label,
    id,
    type = 'text',
    required = false,
    placeholder = '',
    disabled = false,
    width = '100%',
    onChange,
    value
}) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>)=>{
        // Obtiene el valor actual del input
        const value = event.target.value;
        if (type === 'text') { // Verifica si el tipo del input es 'text'
            const isValid = /^[a-zA-Z0-9\s-]*$/.test(value); // Valida el contenido usando expresión regular

            if (isValid) {
                onChange && onChange(event); // Llama a la función onChange si el valor es válido
            }
        }
    }
    return (
        <div style={{ maxWidth: width }} className={"canaia-input-container"}>
            <TextInput
                id={id}
                type={type}
                required={required}
                placeholder={placeholder}
                disabled={disabled}
                labelText={label}
                onChange={handleChange}
                value={value}
            />
        </div>
    );
};