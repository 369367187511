import './SettingsViewSection.scss';
import { CanaiaFormEmpty } from "../../atoms/CanaiaFormEmpty/CanaiaFormEmpty.tsx";
import { CanaiaInput } from "../../atoms/CanaiaInput/CanaiaInput.tsx";
import { useGetAccessToken } from "../../../hooks/useGetAccessToken.tsx";
import useGetPageId from "../../../hooks/useGetPageId.tsx";
import { useEffect, useState } from "react";
import {
    UpdateAssistant,
    UseDeleteAssistant,
    UseFetchOneAssistant,
    UseUpdateAssistant
} from "../../../api/ApiCalls.tsx";
import { Loading, ToastNotification} from "@carbon/react";
import { CanaiaButton } from "../../atoms/CanaiaButton/CanaiaButton.tsx";
import { Save } from "@carbon/icons-react";
import CanaiaDangerModal from "../CanaiaDangerModal/CanaiaDangerModal.tsx";
import { useNavigate } from "react-router-dom";
import {useTranslation} from "react-i18next";

export const SettingsViewSection = () => {
    const token = useGetAccessToken();
    const pageId = useGetPageId();

    const [name, setInputName] = useState('');
    const [deleteName, setDeleteName] = useState('');
    const [messageModal,setMessageModal] = useState('');
    const [message, setMessage] = useState('');
    const [msgType, setMsgType] = useState<'error' | 'info' | 'info-square' | 'success' | 'warning' | 'warning-alt'>('success');
    const [isModalOpen, setModalOpen] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const mutation = UseUpdateAssistant(token, pageId);
    const deleteMutation = UseDeleteAssistant(token, pageId);
    const { data, isLoading, isError, refetch } = UseFetchOneAssistant(token, pageId);

    const navigate = useNavigate();
    const {t} = useTranslation()

    const showTemporaryMessage = (
        msg: string,
        type: 'error' | 'info' | 'info-square' | 'success' | 'warning' | 'warning-alt',
        duration = 3000,
        inModal = false // Nuevo parámetro opcional para mostrar el mensaje en el modal
    ) => {
        if (inModal) {
            setMessageModal(msg);
            setMessage('');
        } else {
            setMessage(msg);
            setMessageModal('');
        }
        setMsgType(type);
        setTimeout(() => {
            if (inModal) {
                setMessageModal('');
            } else {
                setMessage('');
            }
        }, duration);
    };

    useEffect(() => {
        if (data) {
            setInputName(data.name);
        }
    }, [data]);

    if (isLoading) return <Loading withOverlay={false} />;
    if (isError || !data) return <div>{t('settings.error_assistant_data')}</div>;

    const updateName = () => {
        if (!name.trim()) {
            showTemporaryMessage(t('toasts.warning_assistant_name_empty'), 'warning');
            return;
        }

        const dataAssistant: UpdateAssistant = {
            "name": name,
            "type": data.type,
            "transcriber_type": data.transcriber_type,
            "transcriber_endpoint_ms": data.transcriber_endpoint_ms,
            "voice_type": data.voice_type,
            "llm_type": data.llm_type,
            "llm_system_prompt": data.llm_system_prompt,
            "llm_temperature": data.llm_temperature
        };

        mutation.mutate(dataAssistant, {
            onSuccess: () => {
                refetch().then(resp => resp);
                showTemporaryMessage(t('toasts.success_assistant_updated'), 'success');
            },
            onError: () => {
                showTemporaryMessage(t('toasts.error_assistant_updated'), 'error');
            }
        });
    };

    const handleDelete = () => {
        if (deleteName.trim() !== name) {
            // Mensaje en el modal
            showTemporaryMessage(t('toasts.warning_type_assistant_name'), 'warning', 30000, true);
            return;
        }

        setIsDeleting(true); // Bloquear acciones
        deleteMutation.mutate(undefined, {
            onSuccess: () => {
                showTemporaryMessage(t('toasts.success_delete_assistant'), 'success', 3000); // Mostrar el mensaje
                setModalOpen(false); // Cerrar el modal
                refetch().then(resp => resp);

                // Esperar 2 segundos antes de redirigir a otra página
                setTimeout(() => {
                    navigate('/assistants');
                    setIsDeleting(true); // Bloquear acciones
                }, 2000);
            },
            onError: () => {
                showTemporaryMessage(t('toasts.error_delete_assistant'), 'error');
                setIsDeleting(true); // Bloquear acciones
            }
        });
    };

    return (
        <div className="metrics-card assistant-configuration assistant-settings">
            {message && (
                <ToastNotification
                    kind={msgType}
                    title={message}
                    onCloseButtonClick={() => setMessage('')}
                    lowContrast
                />
            )}
            <h4>{t('metrics_pages.settings')}</h4>
            <CanaiaFormEmpty>
                <CanaiaInput
                    id="name"
                    label={t('settings.edit_name')}
                    onChange={(e) => setInputName(e.target.value)}
                    disabled={isDeleting} // Deshabilitar campo de entrada
                    value={name}
                    type="text"
                />
            </CanaiaFormEmpty>
            <div className="form-footer">
                <CanaiaButton
                    onClick={updateName}
                    width="100px"
                    kind="secondary"
                    disabled={mutation.isPending || isDeleting}
                >
                    <Save size={24}/>
                    {mutation.isPending ? t('buttons.saving') : t('buttons.save')}
                </CanaiaButton>
            </div>
            <h5>{t('settings.danger_zone')}</h5>
            <hr />
            <CanaiaButton kind="danger" onClick={() => setModalOpen(true)} disabled={isDeleting}>
                {t('buttons.delete_assistant')}
            </CanaiaButton>
            <CanaiaDangerModal
                open={isModalOpen}
                onClose={() => setModalOpen(false)}
                onPrimaryButtonClick={handleDelete}
                onDisabled={mutation.isPending || isDeleting}
                onSecondaryButtonClick={() => setModalOpen(false)}
                primaryButtonText = {t('buttons.delete')}
                secondaryButtonText = {t('buttons.cancel')}
            >
                <h2>{t('settings.delete_assistant')}!</h2>
                {messageModal !== '' && (
                    <ToastNotification
                        style={{top: '0'}}
                        kind={msgType}
                        title={messageModal}
                        onCloseButtonClick={() => setMessageModal('')}
                        lowContrast
                        aria-hidden={false}
                    />
                )}
                <p>
                    {t('settings.delete_warning')}
                </p>
                <CanaiaInput
                    id="nameToDelete"
                    onChange={(e) => setDeleteName(e.target.value)}
                    value={deleteName}
                    disabled={isDeleting} // Deshabilitar campo de entrada
                    type="text"
                    label={t('settings.delete_assistant')}
                />
            </CanaiaDangerModal>
        </div>
    );
};
