import {MainLayout} from "../../../../ui/layout/MainLayout.tsx";
import {CanaiaButton} from "../../../../ui/atoms/CanaiaButton/CanaiaButton.tsx";
import {UsePostAssistant} from "../../../../api/ApiCalls.tsx";
import {useState} from "react";
import {CanaiaInput} from "../../../../ui/atoms/CanaiaInput/CanaiaInput.tsx"
import {CanaiaTextArea} from "../../../../ui/atoms/CanaiaTextArea/CanaiaTextArea.tsx";
import {CanaiaSlider} from "../../../../ui/atoms/CanaiaSlider/CanaiaSlider.tsx";
import {CanaiaFormEmpty} from "../../../../ui/atoms/CanaiaFormEmpty/CanaiaFormEmpty.tsx";
import {CanaiaTitleWithTooltip} from "../../../../ui/atoms/CanaiaTitleWithTooltip/CanaiaTitleWithTooltip.tsx";
import {CanaiaSubtitleSimple} from "../../../../ui/atoms/CanaiaSubtitleSimple/CanaiaSubtitleSimple.tsx";
import {CanaiaSetOfButtons} from "../../../../ui/atoms/CanaiaSetOfButtons/CanaiaSetOfButtons.tsx";
import {CanaiaTitleWithImage} from "../../../../ui/atoms/CanaiaTitleWithImage/CanaiaTitleWithImage.tsx";
import CanaiaIconLogo from '../../../../assets/images/canaia_icon.svg'
import {Add} from "@carbon/icons-react";
import {useNavigate} from "react-router-dom";
import {AssistantResponse} from "../../../../api/ApiCalls.tsx";
import {authStore} from "../../../../store/authStore.tsx";
import {ToastNotification} from "@carbon/react";
import {useTranslation} from "react-i18next";

export const NewAssistant = () => {
    const {accessToken} = authStore();
    const mutateAssistant = UsePostAssistant(accessToken);

    const [message, setMessage] = useState('');
    const [msgType, setMsgType] = useState<'error' | 'info' | 'info-square' | 'success' | 'warning' | 'warning-alt'>('success');
    const [inputValue, setInputValue] = useState('');
    const [textareaValue, setTextareaValue] = useState('');
    const [sliderValue, setSliderValue] = useState(0.5);
    const [isCreating, setIsCreating] = useState(false);
    const navigate = useNavigate();
    const {t} = useTranslation();
    // Variables de texto para los prompts
    const businessInformation: string = t('create_assistant.business_suggestion')
    const customerSupport: string = t('create_assistant.customer_suggestion')


    const showTemporaryMessage = (
        msg: string,
        type: 'error' | 'info' | 'info-square' | 'success' | 'warning' | 'warning-alt',
        duration = 3000) => {
        setMsgType(type);
        setMessage(msg);
        setTimeout(() => {
            setMessage('');
        }, duration);
    }

    const handleSliderChange = (value: number) => setSliderValue(value);
    const handleButtonClick = (text: string) => setTextareaValue(text);

    const createAssistant = () => {

        if (!inputValue) {
            showTemporaryMessage(t('toasts.warning_assistant_name_empty'), 'warning');
            setIsCreating(false);
            return;
        }

        const dataAssistant = {
            name: inputValue,
            type: "blank",
            transcriber_type: "deepgram:nova-2-es",
            transcriber_endpoint_ms: 0,
            voice_type: "aws-polly:es-neural-lucia",
            llm_type: "anthropic:claude-3-haiku",
            llm_system_prompt: textareaValue,
            llm_temperature: sliderValue
        };


        mutateAssistant.mutate(dataAssistant, {
            onSuccess: (data: AssistantResponse) => {
                setInputValue('');
                setTextareaValue('');
                setSliderValue(0.5);
                setIsCreating(false);
                showTemporaryMessage(t('toasts.success_create_assistant'), 'success');
                setTimeout(() => navigate(`/global/${data.id}`), 2000);
            },
            onError: (mutationError) => {
                console.log(mutationError);
                showTemporaryMessage(t('toasts.error_create_assistant'), 'error');
                setIsCreating(false);
            }
        });
    };

    return (
        <MainLayout>
            <CanaiaTitleWithImage
                title1={t('create_assistant.create_a_new')}
                title2={t('create_assistant.assistant')}
                image={CanaiaIconLogo}
                alt="Logo Canaia"
            />
            <h2 style={{
                textAlign: 'left',
                marginBottom: '3rem',
                fontSize: '1.75rem',
                fontWeight: '700',
                lineHeight: '2.43rem'
            }}>
                {t('create_assistant.tell_us_about_your_assistant')}
            </h2>
            <CanaiaFormEmpty>

                {message && (
                    <ToastNotification
                        kind={msgType}
                        title={message}
                        aria-modal={false}
                        onCloseButtonClick={() => setMessage('')}
                        lowContrast
                    />
                )}

                <CanaiaInput
                    label={t('create_assistant.name')}
                    id="name"
                    type="text"
                    required
                    placeholder={t('create_assistant.the_name_assistant')}
                    disabled={isCreating}
                    onChange={(e) => setInputValue(e.target.value)}
                    value={inputValue}
                />

                <CanaiaTitleWithTooltip
                    title={t('create_assistant.behavior')}
                    tooltipText={t('tooltips.tooltip_behavior')}
                />

                <CanaiaSlider
                    ariaLabelInput=""
                    disabled={isCreating}
                    invalidText=""
                    labelText=""
                    max={1}
                    min={0}
                    step={0.01}
                    stepMultiplier={1}
                    unstable_ariaLabelInputUpper=""
                    value={sliderValue}
                    warnText=""
                    onChangeValue={handleSliderChange}
                />

                <CanaiaTitleWithTooltip
                    title={t('create_assistant.guidelines')}
                    tooltipText={t('tooltips.tooltip_guidelines')}
                />
                <CanaiaSubtitleSimple title={t('create_assistant.suggestions')}/>

                <CanaiaSetOfButtons
                    primaryTitle={t('buttons.business_information')}
                    textPrimaryButton={businessInformation}
                    secondaryTitle={t('buttons.customer_support')}
                    textSecondaryButton={customerSupport}
                    onButtonClick={handleButtonClick}
                    disablePrimary={isCreating}
                    disableSecondary={isCreating}
                />

                <CanaiaTextArea
                    label=""
                    id="prompt"
                    placeholder={t('create_assistant.type_prompt')}
                    required
                    value={textareaValue}
                    onChange={(e) => setTextareaValue(e.target.value)}
                />

                <CanaiaButton
                    kind="secondary"
                    onClick={createAssistant}
                    disabled={isCreating} // Deshabilitar campo de entrada
                >
                    <Add size={28} className="white-color"/>
                    {mutateAssistant.isPending ? t('buttons.saving') : t('buttons.create')}

                </CanaiaButton>
            </CanaiaFormEmpty>
        </MainLayout>
    );
};
