import {create} from 'zustand';

type State = {
    isLoading: boolean
    userName: string
    accessToken: string
    assistantId: string
}

type Actions = {
    setIsLoading: () => void,
    setUserName: (userName: string | undefined) => void
    setAccessToken: (accessToken: State['accessToken']) => void,
    setPageId: (pageId: string) => void
}

export const authStore = create<State & Actions>((set) => ({
    isLoading: false,
    setIsLoading: () => set((state) => ({isLoading: !state.isLoading})),

    userName: '',
    setUserName: (userName) => set(() => ({userName: userName})),

    accessToken: '',
    setAccessToken: (token) => set(() => ({accessToken: token})),

    assistantId: '',
    setPageId: (pageId) => set(() => ({assistantId: pageId})),

}));

