import React, {useEffect, useState} from 'react';
import './MetricsViewSection.scss';
import ConversationsTable from "../ConversationsTable/ConversationsTable.tsx";
import {CanaiaDataPicker} from "../../atoms/CanaiaDataPicker/CanaiaDataPicker.tsx";
import {CanaiaLinearChart} from "../../atoms/CanaiaLineChart/CanaiaLineChart.tsx";
import {CanaiaChartLineMultiLine} from "../../atoms/CanaiaChartLineMultiLine/CanaiaChartLineMultiLine.tsx";
import {UseFetchConversationStats,UseFetchMessagesStats} from "../../../api/ApiCalls.tsx";
import {useGetAccessToken} from "../../../hooks/useGetAccessToken.tsx";
import useGetPageId from "../../../hooks/useGetPageId.tsx";
import {CanaiaFormErrorStatus} from "../../atoms/CanaiaFormErrorStatus/CanaiaFormErrorStatus.tsx";
import {Loading} from "@carbon/react";
import {useTranslation} from "react-i18next";

//Define la estructura de los datos existentes
export interface Messages {
    // Clave de tipo string que representa una fecha
    [date: string]: {
        // Objeto que contiene el conteo de interacciones por canal
        count: {
            // Clave de tipo string que representa el nombre del canal
            [channel: string]: number; // Valor de tipo number que representa la cantidad de interacciones
        };
    };
}
type ChannelGroup = 'Telegram' | 'WhatsApp' | 'Phone' | 'Web';
interface MetricsViewSectionProps {}

export const MetricsViewSection: React.FC<MetricsViewSectionProps> = () => {

    const token = useGetAccessToken();
    const pageId = useGetPageId();
    const {t} = useTranslation();

    // Establece la fecha actual como valor inicial
    const today = new Date();
    //restar un mes
    const lastMonth = new Date(today);
    lastMonth.setMonth(today.getMonth() - 1);
    const formattedCurrentDate = `${lastMonth.getFullYear()}-${(lastMonth.getMonth() + 1).toString().padStart(2, '0')}-${lastMonth.getDate().toString().padStart(2, '0')}`;
    const formattedTomorrowDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1).toISOString().split('T')[0];


    // Se asignan las fechas a los datepicker de inicio
    const [from, setFrom] = useState(formattedCurrentDate);
    const [to, setTo] = useState(formattedTomorrowDate);

    // Maneja el cambio de la fecha de inicio
    const handleDateChangeFrom = (date:string) => {
        setFrom(date);//Actualiza el estado 'from' con la nueva fecha proporcionada
    }
    // Maneja el cambio de la fecha de finalización
    const handleDateChangeTo = (date:string) => {
        setTo(date);// Actualiza el estado 'to' con la nueva fecha proporcionada
    }
    // Efecto que se ejecuta cuando 'formattedCurrentDate' cambia
    useEffect(() => {
        // Actualiza la fecha de inicio con la fecha actual formateada
        handleDateChangeFrom(formattedCurrentDate)
        // Actualiza la fecha de finalización con la fecha actual formateada
        handleDateChangeTo(formattedTomorrowDate);
    },[formattedCurrentDate, formattedTomorrowDate]);// Dependencia: el efecto se ejecuta cada vez que 'formattedCurrentDate' cambia

    //Fetch de datos
    const { data: totalCountData, isFetching: isFetchingMessages } = UseFetchMessagesStats(token, pageId, from, to);
    const { data: totalByChannelData, isFetching: isFetchingConversations } = UseFetchConversationStats(token, pageId, from, to);

    const totalCount = totalCountData as Messages | undefined;
    const totalByChanel = totalByChannelData as Messages | undefined;
    const isLoading = isFetchingMessages || isFetchingConversations;

    // Transforma los datos existentes a un formato adecuado
    const transformData = (data: Messages|undefined)=>{
        if (!data) return [];

        return Object.entries(data).map(([date, {count}])=>{
            // Suma todos los valores de los canales
            const total =  Object.values(count).reduce((acc,value) => acc + value, 0);
            const formattedDate = `${date}T00:00:00`; // Formato de fecha ISO
            return { date: formattedDate, value: total };
        })
    }

    // Transforma los datos por canal a un formato adecuado
    const transformDataByChannels = (data: Messages | undefined) => {
        // Si no hay datos, retorna un array vacío
        if (!data) return [];

        // Mapa que relaciona los nombres de los canales en minúscula con su formato deseado
        const channelMapping: { [key: string]: string } = {
            telegram: 'Telegram',
            whatsapp: 'WhatsApp',
            phone: 'Phone',
            webchat: 'Web',
        };

        // Itera sobre las entradas del objeto 'data'
        return Object.entries(data).flatMap(([date, { count }]) => {
            // Usa 'reduce' para acumular los resultados en un array
            return Object.entries(count).reduce((acc, [channel, value]) => {
                // Obtiene el nombre del grupo usando el mapeo de canales
                const groupName = channelMapping[channel.toLowerCase()];
                if (groupName) {
                    // Si el nombre del grupo es válido, agrega un objeto con la información
                    acc.push({ group: groupName, date: `${date}T00:00:00.000`, value });
                }
                return acc; // Retorna el acumulador
            }, [] as { group: string; date: string; value: number; }[]); // Inicializa el acumulador como un array vacío
        });
    };

    // Asigna los datos a mostrar
    const chartDataMessages = transformData(totalCount);
    const chartDataByChannel = transformDataByChannels(totalByChanel);

    // Función para asignar colores a los grupos de canales
    const scaleColors = (data: { group: string; date: string; value: number; }[]) => {
        // Definición de colores para cada grupo de canales
        const colors: { [key in ChannelGroup]: string } = {
            Telegram: '#29a9ea',
            WhatsApp: '#25d366',
            Phone: '#2c899e',
            Web: '#920985',
        };

        // Crea un conjunto único de grupos a partir de los datos
        const uniqueGroups = new Set(data.map(item => item.group));

        // Reduce el conjunto único a un objeto que asigna colores a los grupos
        return [...uniqueGroups].reduce((acc, group) => {
            // Si el grupo existe en el objeto de colores, se asigna el color correspondiente
            if (group in colors) acc[group as ChannelGroup] = colors[group as ChannelGroup];
            return acc; // Retorna el acumulador actualizado
        }, {} as { [key in ChannelGroup]: string }); // Inicializa el acumulador como un objeto vacío
    };
    const colorsOptions = scaleColors(chartDataByChannel);
    // Verifica si hay datos para mostrar
    const dataValidate = chartDataMessages.length > 0 && chartDataByChannel.length > 0;
    return (
        <div className="metrics-view-section-wrapper">
            <div className="datapickers-wrapper">
                <CanaiaDataPicker text={t('metrics.select_range_start')} onDateChange={handleDateChangeFrom} pickerId={'from'} date={from}/>
                <CanaiaDataPicker text={t('metrics.select_range_end')} onDateChange={handleDateChangeTo} pickerId={'to'} date={to}/>
            </div>
            {isLoading ? (
                <Loading className={"chart-loading"} withOverlay={false}/>
            ): (
                <>
                    {dataValidate ? (
                        <>
                            <CanaiaLinearChart data={chartDataMessages}/>
                            <CanaiaChartLineMultiLine data={chartDataByChannel} colors={colorsOptions}/>
                        </>
                    ):(
                        <div className="no-data-message">
                            <CanaiaFormErrorStatus text={t('metrics.no_statics_to_display')}/>
                        </div>
                    )}
                </>
            )}
            <ConversationsTable/>
        </div>
    );
};