import './App.css';
import { AppRoutes } from "./router/AppRoutes.tsx";
import {AuthProvider} from "./context/AuthContext.tsx";
import { useGetAccessToken } from "./hooks/useGetAccessToken.tsx";
import './i18n/i18n.ts';
import LanguageInitializer from "./i18n/LanguageInitializer.ts";

function App() {

    useGetAccessToken();

    return (
        <AuthProvider>
            <LanguageInitializer />
            <AppRoutes />
        </AuthProvider>
    );
}

export default App;
