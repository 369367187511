import {Layer, Loading, Tab, TabList, TabPanel, TabPanels, Tabs} from "@carbon/react";
import {CanaiaFormEmpty} from "../../../atoms/CanaiaFormEmpty/CanaiaFormEmpty";
import '../FormConfigurations.scss';
import {CanaiaButton} from "../../../atoms/CanaiaButton/CanaiaButton";
import {Save} from "@carbon/icons-react";
import {CanaiaSelect} from "../../../atoms/CanaiaSelect/CanaiaSelect";
import {UpdateAssistant, UseFetchOneAssistant, UseUpdateAssistant} from "../../../../api/ApiCalls.tsx";
import {useGetAccessToken} from "../../../../hooks/useGetAccessToken.tsx";
import useGetPageId from "../../../../hooks/useGetPageId.tsx";
import {CanaiaSlider} from "../../../atoms/CanaiaSlider/CanaiaSlider.tsx";
import {useEffect, useState} from "react";
import {CanaiaTitleWithTooltip} from "../../../atoms/CanaiaTitleWithTooltip/CanaiaTitleWithTooltip.tsx";
import {CanaiaTextArea} from "../../../atoms/CanaiaTextArea/CanaiaTextArea.tsx";
import {CanaiaToastNotification} from "../../../atoms/CanaiaToastNotification/CanaiaToastNotification.tsx";
import {useTranslation} from "react-i18next";
//import {options} from "axios";

// Tipamos el objeto de opciones
const selectOptions: Record<'openai' | 'anthropic', Record<string, string>> = {
    'anthropic': {
        "claude-3-haiku": "anthropic:claude-3-haiku",
        "claude-3-sonnet": "anthropic:claude-3-sonnet",
        "claude-3-opus": "anthropic:claude-3-opus",
        "claude-3.5-sonnet": "anthropic:claude-3.5-sonnet",
    },
    'openai': {
        "gpt-3.5-turbo": "openai:gpt-3.5-turbo",
        "gpt-4-turbo": "openai:gpt-4-turbo",
        "gpt-4o": "openai:gpt-4o",
        "gpt-4o-mini": "openai:gpt-4o-mini",
    }
};

const transcriberTypes: Record<'deepgram' | 'azure', Record<string, string>> = {
    "deepgram": {
        "nova-2-es": "deepgram:nova-2-es",
        "nova-2-en": "deepgram:nova-2-en",
    },
    "azure": {
        "es": "azure:es",
        "en": "azure:en",
    }
}

const voiceTypes: Record<'aws-polly' | 'azure', Record<string, string>> = {
    "aws-polly": {
        "es-neural-lucia": "aws-polly:es-neural-lucia",
        "es-neural-sergio": "aws-polly:es-neural-sergio",
        "es-standard-lucia": "aws-polly:es-standard-lucia",
        "es-standard-conchita": "aws-polly:es-standard-conchita",
        "es-standard-enrique": "aws-polly:es-standard-enrique",
    },
    "azure": {
        "es-es-elvira-neural": "azure:es-es-elvira-neural",
        "es-es-alvaro-neural": "azure:es-es-alvaro-neural",
        "es-es-abril-neural": "azure:es-es-abril-neural",
        "es-es-arnau-neural": "azure:es-es-arnau-neural",
        "es-es-dario-neural": "azure:es-es-dario-neural",
        "es-es-elias-neural": "azure:es-es-elias-neural",
        "es-es-estrella-neural": "azure:es-es-estrella-neural",
        "es-es-irene-neural": "azure:es-es-irene-neural",
        "es-es-laia-neural": "azure:es-es-laia-neural",
        "es-es-lia-neural": "azure:es-es-lia-neural",
        "es-es-nil-neural": "azure:es-es-nil-neural",
        "es-es-saul-neural": "azure:es-es-saul-neural",
        "es-es-teo-neural": "azure:es-es-teo-neural",
        "es-es-triana-neural": "azure:es-es-triana-neural",
        "es-es-vera-neural": "azure:es-es-vera-neural",
        "es-es-ximena-neural-1": "azure:es-es-ximena-neural-1",
        "es-es-arabella-multilingual-neural-1-3": "azure:es-es-arabella-multilingual-neural-1-3",
        "es-es-isidora-multilingual-neural-1-3": "azure:es-es-isidora-multilingual-neural-1-3"
    }
}

interface FormBlankConfig {
    handleSelectChangeGlobal: (selectName: string, value:string)=>void;
}
export const FormBlankConfig : React.FC<FormBlankConfig> = ({handleSelectChangeGlobal}) => {
    const token = useGetAccessToken();
    const pageId = useGetPageId();
    const {t} = useTranslation();

    const [sliderValue, setSliderValue] = useState(0.001);
    const [promptValue, setInputPrompt] = useState('');

    const [selectedRootOption, setSelectedRootOption] = useState<keyof typeof selectOptions>('openai');  // Especificamos el tipo
    const [selectedDetailOption, setSelectedDetailOption] = useState<string>('openai:gpt-3.5-turbo');  // Detalles como "gpt-3.5-turbo", etc.
    //const [selectedDetailOption, setSelectedDetailOption] = useState<string>(selectedDetailOptionGlobal||'openai:gpt-3.5-turbo');  // Detalles como "gpt-3.5-turbo", etc.

    //const [selectedTranscriberRoot, setSelectedTranscriberRoot] = useState<keyof typeof transcriberTypes>(selectedTranscriberGlobal ||'deepgram');
    const [selectedTranscriberRoot, setSelectedTranscriberRoot] = useState<keyof typeof transcriberTypes>('deepgram');
    const [selectedDetailTranscriber, setSelectedDetailTranscriber] = useState<string>('deepgram:nova-2-es');

    const [selectedVoiceRoot, setSelectedVoiceRoot] = useState<keyof typeof voiceTypes>('aws-polly');
    const [selectedDetailVoice, setSelectedDetailVoice] = useState<string>('aws-polly:es-neural-lucia');

    const mutation = UseUpdateAssistant(token, pageId);
    const updatedMutation = UseFetchOneAssistant(token, pageId);

    const {data, isLoading, isError} = UseFetchOneAssistant(token, pageId);
    //funciones para toast
    const [showToast, setShowToast] =  useState(false);
    const [message, setMessage] = useState('');  // Estado para el mensaje
    const [messageType, setMessageType] = useState<'error' | 'info' | 'info-square' | 'success' | 'warning' | 'warning-alt'>('success');

    // Extraer propiedades del asistente
    useEffect(() => {

        // Solo actualiza el estado si los datos están disponibles

        if (data && data.llm_temperature) {
            setSliderValue(data.llm_temperature > 0 ? data.llm_temperature : 0.001);
        }

        if (data && data.llm_system_prompt) {
            setInputPrompt(data.llm_system_prompt);
        }

        if (data && data.llm_type) {
            const rootType = data.llm_type.split(':')[0];  // Obtener la raíz del llm_type
            const detailType = data.llm_type.split(':')[1];  // Obtener el detalle después de los dos puntos

            // Verificar si la raíz es válida y establecer las opciones correspondientes
            if (rootType === 'openai' || rootType === 'anthropic') {
                setSelectedRootOption(rootType as keyof typeof selectOptions);

                // Asegúrate de que detailType sea un valor válido en las opciones detalladas
                if (detailType && Object.prototype.hasOwnProperty.call(selectOptions[rootType], detailType)) {
                    setSelectedDetailOption(`${rootType}:${detailType}`);
                } else {
                    setSelectedDetailOption(''); // O establece a un valor por defecto si el detalle no es válido
                }
            }
        }

        if (data && data.transcriber_type) {
            const transType = data.transcriber_type.split(':')[0];  // Obtener la raíz del transcriber
            const detailTransType = data.transcriber_type.split(':')[1];

            if (transType === 'deepgram' || transType === 'azure') {
                setSelectedTranscriberRoot(transType as keyof typeof transcriberTypes)
                if (detailTransType && Object.prototype.hasOwnProperty.call(transcriberTypes[transType], detailTransType)) {
                    setSelectedDetailTranscriber(`${transType}:${detailTransType}`);
                } else {
                    setSelectedDetailTranscriber(''); // O establece a un valor por defecto si el detalle no es válido
                }

            }
        }

        if (data && data.voice_type) {
            const voiceType = data.voice_type.split(':')[0];  // Obtener la raíz del transcriber
            const detailVoiceType = data.voice_type.split(':')[1];

            if (voiceType === 'aws-polly' || voiceType === 'azure') {
                setSelectedVoiceRoot(voiceType as keyof typeof voiceTypes)
                if (detailVoiceType && Object.prototype.hasOwnProperty.call(voiceTypes[voiceType], detailVoiceType)) {
                    setSelectedDetailVoice(`${voiceType}:${detailVoiceType}`);
                } else {
                    setSelectedDetailVoice(''); // O establece a un valor por defecto si el detalle no es válido
                }

            }
        }


    }, [data]); // Dependencia para que se ejecute al cambiar llm_temperature

    // Manejo de carga y error
    if (isLoading) {
        return <Loading withOverlay={false}/>;
    }

    if (isError || !data) {
        return <div>Error loading assistant data.</div>;
    }

    ////////////////

    const handleRootSelectChange = (value: string) => {
        const newRootOption = value as keyof typeof selectOptions; // Asegúrate de que value sea una clave válida de voiceTypes
        setSelectedRootOption(newRootOption); // Actualiza la opción raíz seleccionada
        // Obtener el primer valor del segundo select utilizando Object.values
        const firstDetailOption = Object.values(selectOptions[newRootOption])[0]; // Accede al primer valor
        // Establecer el primer valor como seleccionado en el estado
        setSelectedDetailOption(firstDetailOption);
    };

    const handleDetailSelectChange = (value: string) => {
        setSelectedDetailOption(value);
        handleSelectChangeGlobal("selectedDetailOption", value); // Actualizar el estado global
    };

    ////////////////

    const handleSelectTranscriber = (value: string) => {
        const newTransOption = value as keyof typeof transcriberTypes; // Asegúrate de que value sea una clave válida de voiceTypes
        setSelectedTranscriberRoot(newTransOption); // Actualiza la opción raíz seleccionada
        // Obtener el primer valor del segundo select utilizando Object.values
        const firstTranscriberDetailOption = Object.values(transcriberTypes[newTransOption])[0]; // Accede al primer valor
        // Establecer el primer valor como seleccionado en el estado
        setSelectedDetailTranscriber(firstTranscriberDetailOption);
        handleSelectChangeGlobal("selectedTranscriber", value); // Actualizar el estado global
    }

    const handleDetailTranscriber = (value: string) => {
        setSelectedDetailTranscriber(value);
    };

    /////////////////

    const handleSelectVoice = (value: string) => {
        const newVoiceOption = value as keyof typeof voiceTypes;
        setSelectedVoiceRoot(value as keyof typeof voiceTypes);  // Tipamos correctamente la opción raíz
        const firstVoiceDetailOption = Object.values(voiceTypes[newVoiceOption])[0];  // Obtener el primer valor del segundo select
        setSelectedDetailVoice(firstVoiceDetailOption);  // Establecer el primer valor como seleccionado
        handleSelectChangeGlobal("selectedVoice", value); // Actualizar el estado global
    }

    const handleDetailVoice = (value: string) => {
        setSelectedDetailVoice(value);
    };

    // Maneja el cambio de opción en el slider
    const handleSliderChange = (value: number) => {
        setSliderValue(value);
    };

    //función para dar formato a la key
    const formatOptionKey = (key:string) =>{
        return key.replace(/-/g, ' ').replace(/\b\w/g, chart => chart.toUpperCase());
    }

    // función para recorrer opciones para dar formato
    const formatOptions = (options: Record<string, string>) => {
        return Object.entries(options).reduce((acc, [key,value])=>{
            acc[formatOptionKey(key)] = value; //llamado de función para formato
            return acc;
        }, {} as Record<string, string>);
    }

    const updateAssistant = () => {

        //Ajuste de Bug visual de Slider
        if (sliderValue === 0.001) {
            setSliderValue(0)
        }

        /*if (!promptValue.trim()) {
            setMessage('The system prompt cannot be empty.');  // Mensaje de error
            setTimeout(() => setMessage(''), 3000);  // Limpiar el mensaje después de 3 segundos
            return; // Detener la ejecución si el campo está vacío
        }*/

        // Objeto que almacena los datos del asistente
        const dataAssistant: UpdateAssistant = {
            "name": data.name,
            "type": data.type,
            "transcriber_type": selectedDetailTranscriber,
            "transcriber_endpoint_ms": data.transcriber_endpoint_ms,
            "voice_type": selectedDetailVoice,
            "llm_type": selectedDetailOption,
            "llm_system_prompt": promptValue,
            "llm_temperature": sliderValue
        }

        mutation.mutate(dataAssistant, {
            onSuccess: () => {
                updatedMutation.refetch().then(resp => resp);
                setMessage('Assistant updated successfully!');  // Mensaje de éxito
                setMessageType('success');
                setShowToast(true);
            },
            onError: () => {
                setMessage('Error updating assistant.');  // Mensaje de error
                setMessageType('error');  // Tipo de mensaje
                setShowToast(true);
            }
        })

    }

    return (
        <div className="metrics-card assistant-configuration">
            {showToast && (
                <CanaiaToastNotification
                    message={message}
                    type={messageType}
                    onClose={()=>setShowToast(false)}
                />
            )}
            <Tabs>
                <TabList aria-label="List of tabs" contained fullWidth>
                    <Tab>{t('channels.details')}</Tab>
                    <Tab>{t('channels.transcriber')}</Tab>
                    <Tab>{t('channels.voice')}</Tab>
                    <Tab disabled={true}>{t('channels.advanced')}</Tab>
                </TabList>
                <CanaiaFormEmpty>
                    <TabPanels>
                        <TabPanel key="details">
                            <Layer>
                                <CanaiaTextArea
                                    id='transcriber_endpoint'
                                    label={t('channels.system_prompt')}
                                    disabled={false}
                                    onChange={(e) => setInputPrompt(e.target.value)}
                                    value={promptValue}
                                />
                                <CanaiaSelect
                                    id={'selectIdValue'}
                                    options={{Openai: 'openai', Anthropic: 'anthropic'}}  // Opciones del primer select
                                    value={selectedRootOption}  // Valor seleccionado en el primer select
                                    onChange={handleRootSelectChange}
                                    labelText={t('channels.llm_provider')}
                                />
                                {selectedRootOption && (
                                    <CanaiaSelect
                                        id="detail-select"
                                        options={formatOptions(selectOptions[selectedRootOption])}  // Opciones basadas en la selección del primer select
                                        value={selectedDetailOption}  // Valor seleccionado en el segundo select
                                        onChange={handleDetailSelectChange}
                                        labelText={t('channels.llm_model')}

                                    />
                                )}
                                <CanaiaTitleWithTooltip
                                    title={t('create_assistant.behavior')}
                                    tooltipText={t('tooltips.tooltip_behavior')}
                                />
                                <CanaiaSlider
                                    ariaLabelInput="Slider de ejemplo"
                                    disabled={false}
                                    invalidText="Valor inválido"
                                    labelText="Slider de ejemplo"
                                    max={1}
                                    min={0}
                                    step={0.01}
                                    stepMultiplier={1}
                                    unstable_ariaLabelInputUpper="Slider de ejemplo (superior)"
                                    value={sliderValue}
                                    warnText="Valor no válido"
                                    onChangeValue={handleSliderChange}
                                />
                            </Layer>
                        </TabPanel>
                        <TabPanel key={'transcriber'}>
                            <Layer>
                                <CanaiaSelect
                                    id={'selectedTranscriberRoot'}
                                    options={{Deepgram: 'deepgram', Azure: 'azure'}}
                                    value={selectedTranscriberRoot}   // Le pasamos el valor seleccionado
                                    onChange={handleSelectTranscriber}
                                    labelText={t('channels.transcriber_type')}
                                />
                                {selectedTranscriberRoot && (
                                    <CanaiaSelect
                                        id="detail-select"
                                        options={formatOptions(transcriberTypes[selectedTranscriberRoot])}  // Opciones basadas en la selección del primer select
                                        value={selectedDetailTranscriber}  // Valor seleccionado en el segundo select
                                        onChange={handleDetailTranscriber}
                                        labelText={t('channels.transcriber_detail')}
                                    />
                                )}
                            </Layer>
                        </TabPanel>
                        <TabPanel key={'voice'}>
                            <Layer>
                                <CanaiaSelect
                                    id={'selectedDetailVoice'}
                                    options={{'Aws Polly': 'aws-polly', 'Azure': 'azure'}}
                                    value={selectedVoiceRoot}   // Le pasamos el valor seleccionado
                                    onChange={handleSelectVoice}
                                    labelText={t('channels.voice_type')}
                                />
                                {selectedVoiceRoot && (
                                    <CanaiaSelect
                                        id="detail-select"
                                        options={formatOptions(voiceTypes[selectedVoiceRoot])}  // Opciones basadas en la selección del primer select
                                        value={selectedDetailVoice}  // Valor seleccionado en el segundo select
                                        onChange={handleDetailVoice}
                                        labelText={t('channels.voice_detail')}
                                    />
                                )}
                            </Layer>
                        </TabPanel>
                        <TabPanel key={'advanced'}>
                            <Layer>
                                <h3>{t('channels.advanced')}</h3>
                            </Layer>
                        </TabPanel>
                    </TabPanels>
                </CanaiaFormEmpty>
                <div className="form-footer">
                    <CanaiaButton
                        onClick={updateAssistant}
                        width="100px"
                        kind="secondary"
                        disabled={mutation.isPending} // Deshabilitar mientras está salvando
                    >
                        <Save size={24}/>
                        {mutation.isPending ? t('buttons.saving') : t('buttons.save')}
                    </CanaiaButton>
                </div>
            </Tabs>
        </div>
    );
};