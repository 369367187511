import React from "react";
import './CanaiaTitleSimple.scss';

interface CanaiaTitleSimpleProps {
    title: string;
}

export const CanaiaTitleSimple: React.FC<CanaiaTitleSimpleProps>= ({title}) =>{
    return (
        <div className="canaia-title-simple-wrapper">
            <span>{title}</span>
        </div>
    );
}