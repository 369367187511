//
//
//  Sign In Callback Page
//
//

import {useHandleSignInCallback} from "@logto/react";
import {useNavigate} from "react-router-dom";
import {InlineLoading} from "@carbon/react";

function SignInCallback() {
    const navigate = useNavigate()

    const { isLoading, error } = useHandleSignInCallback(() => {
        navigate("/")
    });

    // When it's working in progress
    if (isLoading) {
        return <InlineLoading status="active" iconDescription="Loading" description="Redirecting..."/>
    }

    if(error) {
        return <div>Hubo un error al autentificar ... </div>
    }

    return null;
}

export default SignInCallback
