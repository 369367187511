import { ChatContainer, Message, MessageInput, MessageList, TypingIndicator } from "@chatscope/chat-ui-kit-react";
import { useState } from "react";
import './PlayGroundChat.scss';
import { useGenerateUUID } from "../../../hooks/useGetUuid.tsx";
import { useLocation } from "react-router-dom";
import { UseWebChat } from "../../../api/ApiCalls.tsx";
import { CanaiaFormErrorStatus } from "../../atoms/CanaiaFormErrorStatus/CanaiaFormErrorStatus.tsx";
import {useTranslation} from "react-i18next";

interface PlaygroundChatProps {
    webToken?: string | null;
}

export const PlaygroundChat = ({ webToken }: PlaygroundChatProps) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const tokenId = searchParams.get('tokenId'); // Obtener el token de la URL
    const uuid = useGenerateUUID();
    const {t} = useTranslation();

    const [inputValue, setInputValue] = useState('');
    const [isTyping, setIsTyping] = useState(false);

    // Estado de mensajes
    const [messages, setMessages] = useState([
        {
            message: t('playground.hello_how'),
            sender: "Bot",
        },
    ]);

    if (webToken === undefined || webToken === null) {
        webToken = tokenId;
    }

    if (!webToken) {
        return <CanaiaFormErrorStatus text={'Please try again...'} />;
    }

    // Hook para manejar el envío de mensajes al bot
    const { mutate: sendMessage } = UseWebChat(webToken);

    // Función para manejar el envío de mensajes del usuario
    const handleSend = () => {
        if (inputValue.trim() === '') return; // Evita enviar mensajes vacíos

        const newMessage = {
            message: inputValue,
            sender: "User",
        };
        setMessages([...messages, newMessage]);
        setInputValue(''); // Limpiar el campo de entrada
        setIsTyping(true); // Activar el TypingIndicator

        // Enviar el mensaje al bot utilizando la API
        sendMessage({
            chat_id: uuid,
            message: inputValue.trim(),
        }, {
            onSuccess: (response) => {
                setIsTyping(false); // Desactivar el TypingIndicator

                // Asegúrate de que response.response sea un string
                const botMessage = Array.isArray(response.response)
                    ? response.response.join(', ') // Unir los mensajes en una cadena si es un array
                    : response.response || t("playground.bot_not_answering");

                const botReply = {
                    message: botMessage,
                    sender: "Bot",
                };
                setMessages(prevMessages => [...prevMessages, botReply]);
            },
            onError: (error) => {
                setIsTyping(false); // Desactivar el TypingIndicator
                const errorMessage = {
                    message: `<span style=" color: red;">${t("playground.an_error_occurred")}</span>`,
                    sender: "System",
                };
                setMessages(prevMessages => [...prevMessages, errorMessage]);
                console.error("Error al enviar el mensaje al bot:", error);
            }
        });
    };

    return (
        <div className={'playground-wrapper'}>
            <ChatContainer>
                <MessageList
                    scrollBehavior="smooth"
                    typingIndicator={isTyping ? <TypingIndicator content={t('playground.bot_is_typing')} /> : null}
                >
                    {messages.map((msg, index) => (
                        <Message
                            key={index}
                            model={{
                                message: msg.message,
                                sender: msg.sender,
                                direction: msg.sender === "User" ? 'outgoing' : 'incoming',
                                position: 'single',
                            }}
                        />
                    ))}
                </MessageList>
                <MessageInput
                    value={inputValue}
                    onChange={(value) => setInputValue(value)}
                    attachButton={false}
                    sendButton={false}
                    onSend={handleSend}
                    placeholder={t('playground.type_text')}
                />
            </ChatContainer>
        </div>
    );
};
