import { MainLayout } from "../../../../ui/layout/MainLayout.tsx";
import { AssistantsGrid } from "./AssistantsGrid.tsx";
import { authStore } from "../../../../store/authStore.tsx";
import { UseFetchAssistants } from "../../../../api/ApiCalls.tsx";
import { Loading } from "@carbon/react";
import './ViewAssistants.scss';
import { NoAssistantsView } from "./NoAssistantsView.tsx";
import { Dashboard } from "@carbon/icons-react";
import { useEffect, useState } from "react";
import { useGetAccessToken } from "../../../../hooks/useGetAccessToken.tsx";
import {useTranslation} from "react-i18next";

export const ViewAssistants = () => {
    const { userName } = authStore.getState();
    const accessToken = useGetAccessToken(); // Obtiene el token de acceso
    const { data, isLoading, isError, isFetching } = UseFetchAssistants(accessToken); // Fetch de asistentes
    const [Loader, setLoader] = useState(true);
    const [dataRows, setDataRows] = useState([]);
    const { t } = useTranslation();


    useEffect(() => {
        if (data) {
            setDataRows(data.items || []); // Asegúrate de que data.items sea un array
            setLoader(false);
        }
    }, [data]);

    // Estado de carga
    if (isLoading || isFetching || Loader) {
        return (
            <MainLayout>
                <Loading className="table-loading" withOverlay={false} />
            </MainLayout>
        );
    }

    // Estado de error
    if (isError) {
        return (
            <MainLayout>
                <Loading className="table-loading" withOverlay={false} />
            </MainLayout>
        );
    }

    // Mostrar NoAssistantsView si no hay asistentes
    if (dataRows.length === 0) {
        return (
            <MainLayout>
                <NoAssistantsView />
            </MainLayout>
        );
    }

    // Renderizar asistentes cuando los datos son válidos
    return (
        <MainLayout>
            <h2 className={'welcome-title'}>
                {t('assistants.welcome')} <span>{userName}</span>
            </h2>
            <div className={'welcome-subtitle primary-color'}>
                <span><Dashboard size={24} /></span>
                {t('assistants.overview')}
            </div>
            <AssistantsGrid data={dataRows} />
        </MainLayout>
    );
};
