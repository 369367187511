import React from "react";
import {Tooltip} from "@carbon/react";
import { Information } from '@carbon/icons-react';
import './CanaiaTitleWithTooltip.scss'

interface CanaiaTitleWithTooltipProps {
    title: string;
    tooltipText: string;
}

export const CanaiaTitleWithTooltip: React.FC<CanaiaTitleWithTooltipProps> =({
    title,
    tooltipText,
})=>{
    return (
        <div className="canaia-title-with-tooltip-container">
            <span>{title}</span>
            <Tooltip  label={tooltipText}>
                <button className="sb-tooltip-trigger" type="button">
                    <Information />
                </button>
            </Tooltip>
        </div>
    );
};